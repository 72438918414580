import './index.css'
import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  BrowserRouter,
} from 'react-router-dom'
import Layout from './containers/layout/Layout'
import Home from './pages/home/Home'
import Signup from './pages/signup/Signup'
import Signup2 from './pages/signup2/Signup2'
import Signin from './pages/signin/Signin'
import Signin2 from './pages/signin2.js/Signin2'
import SentEmail from './pages/sentEmail/SentEmail'
import Activate from './pages/activate/Activate'
import ForgotPassword from './pages/forgotPassword/ForgotPassword'
import ResetPassword from './pages/ResetPassword/ResetPassword'
import MaleQuestionnaire from './pages/questionnaire/MaleQuestionnaire'
import FemaleQuestionnaire from './pages/questionnaire/FemaleQuestionnaire'
import QuestionnaireSelection from './pages/questionnaire/QuestionnaireSelection'
import MalePatientInfo from './components/PatientSummary/MalePatientInfo'
import FemalePatientInfo from './components/PatientSummary/FemalePatientInfo'
import NoMatch from './pages/404/NoMatch'

const App = () => {
  const [user, setUser] = useState(null)

  console.log('app')

  const getUserInfo = (value) => {
    // console.log("line 19 user Value: ", value);
    if (value !== undefined) {
      return setUser(value)
    }
    // console.log("line 22 user: ", user);
  }

  // Later remove this it is added for fixing the hot reload issue of reactJS
  useEffect(() => {
    window.process = {
      ...window.process,
    }
  }, [])

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={QuestionnaireSelection}></Route>
        <Route exact path='/male' component={MaleQuestionnaire}></Route>
        <Route exact path='/female' component={FemaleQuestionnaire}></Route>
        {/* <Route path='/admin'> */}
        <Route exact path='/admin' component={Home} />
        <Route
          exact
          path='/admin/male/questionnaire/:objId'
          component={MalePatientInfo}
        />
        <Route
          exact
          path='/admin/female/questionnaire/:objId'
          component={FemalePatientInfo}
        />
        <Route path='admin/signup' component={Signup} />
        {/* <Route exact path="/signup2" component={Signup2} /> */}
        <Route
          exact
          path='/admin/signup2'
          render={() => <Signup2 getUserInfo={getUserInfo} />}
        />
        <Route exact path='/admin/signin' component={Signin} />
        <Route exact path='/admin/signin2' component={Signin2} />
        <Route exact path='/admin/forgotpassword' component={ForgotPassword} />
        <Route
          exact
          path='/auth/resetpassword/:token'
          component={ResetPassword}
        />
        <Route exact path='/auth/activate/:token' component={Activate} />
        {user && (
          <Route
            exact
            path='/admin/sentemail'
            render={() => <SentEmail user={user} />}
          />
        )}
        <Route path='*'>
          <NoMatch />
        </Route>

        {/* </Route> */}
      </Switch>
    </BrowserRouter>
  )
}

export default App
