import styles from './Home.module.css'
import TableViewer from './TableViewer'

import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import Button from 'react-bootstrap/Button'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Spinner from 'react-bootstrap/Spinner'

// import Layout from "../../containers/layout/Layout";
import Layout2 from '../../containers/layout/Layout2'
// import { socialIcons } from "../../utils/data";
import { isAuth, signout } from '../../helpers'
// import Navbar from "../../components/Navbar/Navbar";

import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// import 'react-tabulator/lib/styles.css' // default theme
// import 'react-tabulator/css/tabulator_modern.min.css' // use Theme(s)
// import 'react-tabulator/css/tabulator_bulma.css' // use Theme(s)
// import { ReactTabulator, reactFormatter } from 'react-tabulator'
import { reactFormatter } from 'react-tabulator'
// import DateEditor from 'react-tabulator/lib/editors/DateEditor'
// import MultiValueFormatter from 'react-tabulator/lib/formatters/MultiValueFormatter'
// import MultiSelectEditor from 'react-tabulator/lib/editors/MultiSelectEditor'
// import Table from 'rc-table'

import PatientFullInfo from '../../components/PatientSummary/MalePatientInfo'

window.luxon = require('luxon')

const options = {
  height: '100%',
  // minHeight: 900,
  // width: '100%',
  pagination: 'local',
  paginationSize: 30,
  // movableRows: false,
  responsiveLayout: 'hide',
}

const Home = ({ history }) => {
  const [isClicked, setIsClicked] = useState(false)
  const [patientData, setPatientData] = useState(false)
  const [currentPatientData, setCurrentPatientData] = useState(false)

  let auth = isAuth()

  const handleClick = (e) => {
    e.preventDefault()
    // console.log('clicked')
    setIsClicked(true)
  }

  useEffect(() => {
    // let token = match.params.token;
    if (auth) {
      getPatientData(auth.email)
    }
  }, [])

  const getPatientData = async (email) => {
    try {
      const res = await axios.get('/api/auth/users/questionnaires', {
        params: {
          email,
        },
      })
      setPatientData(res.data.patientQuestionnaireList)
    } catch (err) {
      console.log('Failed to get patients data')
      console.log(err)
      console.log('err response: ', err.response)
      toast.error(err.response.data.err)
    }
  }

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  function PatientFullInfoBtn(props) {
    return (
      <Button
        variant='primary'
        href={`/admin/${props.cell._cell.row.data.gender}/questionnaire/${props.cell._cell.row.data.objId}`}
        target='_blank'
        rel='noreferrer'
      >
        Report
      </Button>
    )
    // return (
    //   <Button
    //     variant='primary'
    //     onClick={() => {
    //       setCurrentPatientData(props)
    //       setShow(true)
    //     }}
    //   >
    //     Report
    //   </Button>
    // )
  }

  const columns = [
    {
      title: 'Timestamp',
      field: 'timestamp',
      headerFilter: 'input',
      sorter: 'datetime',
      sorterParams: {
        format: 'M/d/yyyy H:mm:ss',
      },
    },
    {
      title: 'Gender',
      field: 'gender',
      // editor: 'list',
      // editorParams: {
      //   values: { male: 'Male', female: 'Female', clearable: true },
      // },
      headerFilter: 'list',
      headerFilterParams: {
        values: { male: 'Male', female: 'Female' },
        clearable: true,
      },
    },
    { title: 'Name', field: 'name', headerFilter: 'input' },
    {
      title: 'DOB',
      field: 'date_of_birth',
      headerFilter: 'input',
      sorter: 'date',
      sorterParams: {
        format: 'M/d/yyyy',
      },
    },
    // { title: 'Email', field: 'email_address', headerFilter: 'input' },
    // { title: 'Address', field: 'street_address', headerFilter: 'input' },

    { title: 'City', field: 'city', headerFilter: 'input' },
    { title: 'State', field: 'state', headerFilter: 'input' },
    // { title: 'Zip', field: 'zip', headerFilter: 'input' },

    {
      title: 'Report',
      field: 'custom',
      hozAlign: 'center',
      formatter: reactFormatter(<PatientFullInfoBtn />),
      // formatter: '<button>Hi there</button>',
    },
  ]

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement='top'
        className='h-100'
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Full Information</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {currentPatientData !== false ? (
            <PatientFullInfo currentPatientData={currentPatientData} />
          ) : (
            'Error'
          )}
        </Offcanvas.Body>
      </Offcanvas>

      {isClicked ? <Redirect to='/admin/signup2' /> : null}
      <Layout2>
        <section className={styles.hero}>
          <div className={styles.heroSection}>
            {/* <p>If you like it, please give me a star</p> */}

            {!auth && (
              <>
                <h1>Practitioner</h1>
                <h1>Admin Panel</h1>
                <br />
                <p> Register and login to get started</p>
                <button className={styles.btn} onClick={handleClick}>
                  Get Started
                </button>
                <div className={styles.animation}>
                  <img src='/images/background.png' alt='hero-img' />
                </div>
              </>
            )}

            {auth && !patientData && (
              <>
                <Spinner animation='border' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </Spinner>
              </>
            )}

            {auth && patientData && patientData.length > 0 && (
              <>
                <TableViewer
                  columns={columns}
                  data={patientData}
                  // Initial sort added in parent component
                />
                {/* <ReactTabulator
                  // className={styles.table}
                  // onRef={(ref) => (this.ref = ref)}
                  columns={columns}
                  data={patientData}
                  options={options}
                  tooltips={true}
                  layout={'fitColumns'}
                  printAsHtml={true}
                  dataLoaderLoading='dataLoaderLoading'
                  responsiveLayout='hide'
                /> */}
              </>
            )}

            {auth && patientData && patientData.length === 0 && (
              <>
                <p className='text-center'>
                  No patient has completed a questionnaire so far.
                </p>
                <p className='text-center'>
                  As soon as one has, you will find the completed questionnaire
                  here.
                </p>
              </>
            )}
          </div>
        </section>
      </Layout2>
    </>
  )
}

export default Home
