import { TabulatorFull as Tabulator } from 'tabulator-tables'
import { useEffect, useRef } from 'react'

import 'tabulator-tables/dist/css/tabulator_semanticui.min.css'

const TableViewer = ({ data, columns, props }) => {
  const tableRef = useRef()

  useEffect(() => {
    let table = new Tabulator(tableRef.current, {
      data: data,
      columns: columns,
      layout: 'fitColumns',
      pagination: true,
      paginationSize: 10,
      paginationSizeSelector: [10, 25, 50, 100],
      initialSort: [
        {
          column: 'timestamp',
          dir: 'desc',
        },
      ],
    })

    return () => {
      table.on('tableBuilt', () => {
        table.destroy()
        console.log('Table Destroyed')
        table.setData(data)
        table.setColumns(columns)
        console.log('Table Reinit')
      })
    }
  }, [data, columns])

  return <div ref={tableRef} className='celled compact' />
}

export default TableViewer
