import React, { useState } from 'react'
import FormContext from './form-context'

function MaleFormProvider({ children }) {
  const defaultDataState = {
    gender: 'male',
    // current_feet: 5,
    // current_inches: 6,
    // tallest_feet: 5,
    // tallest_inches: 6,
    // weight_at_age_25: 150,
    // current_weight: 150,
    // cups_currently: 0,
    // cups_in_past: 0,
    // cigar_currently: 0,
    // cigar_in_past: 0,
    // root_canals_yes_how_many: 0,
    // dental_implants_yes_how_many: 0,
    // how_many_children_do_you_have: 0,
    // what_percentage_of_your_diet_is_organic: 25,
    // number_of_bowel_movements_per_week: 7,
  }
  const defaultValidationState = {}
  const [page, setPage] = useState(1)
  const [finalData, setFinalData] = useState([])
  const [formData, setFormData] = useState(defaultDataState)
  const [validation, setValidation] = useState(defaultValidationState)

  const validate = (a) => {
    return Object.values(a).reduce((sum, next) => sum && next, true)
  }

  const checkNumber = {
    async validator({ field }, value) {
      if (!value) {
        return Promise.resolve()
      }
      if (isNaN(value)) {
        return Promise.reject('Number only!')
      }
      return Promise.resolve()
    },
  }

  const resetDataState = () => {
    setFormData(defaultDataState)
    setValidation(defaultValidationState)
  }

  const setCurrentPage = (val) => {
    setPage(val)
  }

  const initialValue = {
    formData,
    page,
    numOfPages: 12,
    finalData,
    validation,
    setCurrentPage,
    setFormData,
    resetDataState,
    setFinalData,
    setValidation,
    validate,
    checkNumber,
  }

  return (
    <FormContext.Provider value={initialValue}>{children}</FormContext.Provider>
  )
}

export default MaleFormProvider
