import styles from './Navbar.module.css'
import React, { useState, useEffect } from 'react'
import { Link, useLocation, matchPath } from 'react-router-dom'

const NavbarEmpty = () => {
  let checkAdminURL = matchPath(useLocation().pathname, {
    path: '/admin',
    exact: true,
    strict: false,
  })

  console.log(checkAdminURL)

  const [showLists, setShowLists] = useState(false)
  const [show, setShow] = useState(true)
  const [scrollPos, setScrollPos] = useState(0)

  const handleScroll = () => {
    setScrollPos(document.body.getBoundingClientRect().top)
    setShow(document.body.getBoundingClientRect().top > scrollPos)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [show, scrollPos])

  const handleClick = (e) => {
    e.preventDefault()
    // console.log('clicked')
    setShowLists(!showLists)
    const target = e.target.getAttribute('href')
    const location = document.querySelector(target).offsetTop

    window.scrollTo({
      left: 0,
      top: location - 85,
    })
  }

  return (
    <>
      <nav className={`${styles.navbar} `}>
        <div
          className={`${styles.navCenter} ${
            show ? styles.active : styles.hidden
          }`}
          style={{ justifyContent: 'center' }}
        >
          <div
            className={styles.navHeader}
            style={{ justifyContent: 'center' }}
          >
            <h2>
              <Link to='/admin'>
                <img
                  className={styles.logo}
                  src='images/logo.png'
                  alt='brite live logo'
                />
              </Link>
            </h2>
          </div>
        </div>
      </nav>
    </>
  )
}

export default NavbarEmpty
