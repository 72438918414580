import React, { useState } from 'react'
import FormContext from './form-context'

function FemaleFormProvider({ children }) {
  const defaultDataState = {
    gender: 'female',
    // cups_currently: 0,
    // cups_in_past: 0,
    // cigar_currently: 0,
    // cigar_in_past: 0,
    // root_canals_yes_how_many: 0,
    // dental_implants_yes_how_many: 0,
    // how_many_children_do_you_have: 0,
    // what_percentage_of_your_diet_is_organic: 25,
    // number_of_bowel_movements_per_week: 7,
    // current_feet: 5,
    // current_inches: 6,
    // tallest_feet: 5,
    // tallest_inches: 6,
    // weight_at_age_25: 150,
    // current_weight: 150,
    // your_age_at_the_time_of_your_last_menstrual_period: 50,
    // your_age_at_the_time_your_menstrual_periods_were_last_regular: 40,
    // age_of_onset_of_menstruation: 12,
    // how_many_years_after_menarche_did_your_periods_become_regular: 3,
    // how_many_days_did_your_menstrual_flow_last_at_that_time: 4,
    // during_your_early_adulthood_what_was_the_most_common_length_of_your_complete_cycle: 28,
    // iud_number_of_years: 0,
    // number_of_years: 0,
    // number_of_miscarriages: 0,
    // number_of_abortions: 0,
    // total_number_of_times_you_have_been_pregnant: 0,
    // number_of_live_births: 0,
    // your_age_at_1_st_pregnancy: 25,
    // your_age_at_2_nd_pregnancy: 28,
    // your_age_at_3_rd_pregnancy: 30,
    // your_age_at_4_th_pregnancy: 32,
    // your_age_at_5_th_pregnancy: 35,
    // your_age_at_6_th_pregnancy: 38,
    // number_of_months_you_breastfed_1_st_baby: 18,
    // number_of_months_you_breastfed_2_nd_baby: 18,
    // number_of_months_you_breastfed_3_rd_baby: 18,
    // number_of_months_you_breastfed_4_th_baby: 18,
    // number_of_months_you_breastfed_5_th_baby: 18,
    // number_of_months_you_breastfed_6_th_baby: 18,
    // if_yes_at_what_age: 40,
    // if_so_how_many: 1,
    // ultrasounds_if_so_how_many: 1,
    // thermograms_if_so_how_many: 1,
    // if_so_at_what_age_implanted: 25,
    // what_of_time_in_a_24_hour_day_do_you_wear_a_bra: 30,
    // at_what_age: 40,
    // age_of_last_pap_smear: 40,
    // number_of_bone_density_tests: 0,
    // age_at_last_bone_density_test: 45,
    // premarin_number_of_years: 0,
    // prempro_number_of_years: 0,
    // patch_number_of_years: 0,
  }
  const defaultValidationState = {}
  const [page, setPage] = useState(1)
  const [finalData, setFinalData] = useState([])
  const [formData, setFormData] = useState(defaultDataState)
  const [validation, setValidation] = useState(defaultValidationState)

  const checkNumber = {
    async validator({ field }, value) {
      if (!value) {
        return Promise.resolve()
      }
      if (isNaN(value)) {
        return Promise.reject('Number only!')
      }
      return Promise.resolve()
    },
  }

  const validate = (a) => {
    return Object.values(a).reduce((sum, next) => sum && next, true)
  }

  const resetDataState = () => {
    setFormData(defaultDataState)
    setValidation(defaultValidationState)
  }

  const setCurrentPage = (val) => {
    setPage(val)
  }

  const initialValue = {
    formData,
    page,
    numOfPages: 12,
    finalData,
    validation,
    setCurrentPage,
    setFormData,
    resetDataState,
    setFinalData,
    setValidation,
    validate,
    checkNumber,
  }

  return (
    <FormContext.Provider value={initialValue}>{children}</FormContext.Provider>
  )
}

export default FemaleFormProvider
