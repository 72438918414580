import React from 'react'
import { Link } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { BsGenderFemale, BsGenderMale } from 'react-icons/bs'

import { Button } from 'antd'

import LogoHeader from './components/LogoHeader'

function QuestionnaireSelection() {
  let doc = new URLSearchParams(window.location.search).get('doc')
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#47bea7',
          fontFamily: 'Poppins',
          // borderRadius: "",
        },
      }}
    >
      <div
        className='questionnaire-selection-background'
        loading='lazy'
        style={{ backgroundImage: 'url(/questionnaire_bg.jpg)' }}
      >
        <div className='questionnaire-selection-container'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1.5rem',
              alignItems: 'center',
            }}
          >
            <LogoHeader />
            <h1 style={{ textAlign: 'center', fontSize: '2rem' }}>
              Questionnaire Selection
            </h1>
            <Link to={doc ? `/male?doc=${doc}` : `/male`}>
              <Button type='primary' className='selection-btn'>
                <BsGenderMale style={{ marginRight: '10px' }} /> Male
                Questionnaire
              </Button>
            </Link>
            <Link to={doc ? `/female?doc=${doc}` : `/female`}>
              <Button type='primary' className='selection-btn'>
                <BsGenderFemale style={{ marginRight: '10px' }} /> Female
                Questionnaire
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </ConfigProvider>
  )
}

export default QuestionnaireSelection
