import React from 'react'
import { useLocation } from 'react-router-dom'
import Layout2 from '../../containers/layout/Layout2'
import Layout from '../../containers/layout/Layout'
import { Link, Redirect } from 'react-router-dom'
import NavbarEmpty from '../../components/Navbar/NavbarEmpty'
import Navbar from '../../components/Navbar/Navbar'

function NoMatch() {
  let isAdminPage = window.location.pathname.includes('/admin/')

  return (
    <>
      {!isAdminPage ? <NavbarEmpty /> : <Navbar />}
      <div
        className='container '
        style={{
          border: '0px solid red',
          maxWidth: '1072px',
          // height: "100vh",
          minHeight: '50vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '10px',
        }}
      >
        <h1
          style={{
            width: '100%',
            textAlign: 'center',
            // marginBottom: '15px',
            margin: '0',
            lineHeight: '1',
            fontSize: '9rem',
            fontWeight: '700',
            color: 'rgb(70, 190, 167)',
          }}
        >
          404
        </h1>
        <h3
          style={{
            width: '100%',
            textAlign: 'center',
            // marginBottom: '10px',
            // lineHeight: '1.5',
            fontSize: '2.5rem',
            fontWeight: '600',
          }}
        >
          Ooops!!
        </h3>
        <h3
          style={{ textAlign: 'center', lineHeight: '1.5', fontSize: '18px' }}
        >
          {/* <code>{location.pathname}</code> */}
          That page doesn't exist or is unavailable.
        </h3>
        <Link to={isAdminPage ? '/admin' : '/'}>
          <button className='btn btn-primary ms-3' type='reset'>
            Go Home
          </button>
        </Link>
      </div>
    </>
  )
}

export default NoMatch
