import React from 'react'
import { useLocation } from 'react-router-dom'

import { ConfigProvider } from 'antd'

import LogoHeader from './components/LogoHeader'
import LandingFormPage from './components/male/LandingFormPage'
import MaleFormProvider from './store/MaleFormProvider'

function MaleQuestionnaire() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#47bea7',
          fontFamily: 'Poppins',
          // borderRadius: "",
        },
      }}
    >
      <MaleFormProvider>
        <div className='questionnaire-background'>
          <div className='questionnaire-container'>
            <LogoHeader />
            <LandingFormPage />
          </div>
        </div>
      </MaleFormProvider>
    </ConfigProvider>
  )
}

export default MaleQuestionnaire
