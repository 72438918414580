import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { isAuth } from '../../helpers'

import axios from 'axios'

import './PatientFullInfo.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.js'

import { DateTime } from 'luxon'

export default function FemalePatientInfo({ currentPatientData }) {
  let { objId } = useParams()
  const [rd, setRd] = useState(false)

  let auth = isAuth()
  const history = useHistory()

  useEffect(() => {
    // let token = match.params.token;
    if (auth && auth.email) {
      getPatientData(auth.email)
    } else {
      history.push('/admin/signin2')
    }
  }, [])

  useEffect(() => {
    if (rd) {
      afterGettingData()
    }
  }, [rd])

  const getPatientData = async (email) => {
    try {
      const res = await axios.get('/api/auth/users/questionnaire', {
        params: {
          gender: 'female',
          objId: objId,
          email: email,
        },
      })
      setRd(res.data.patientQuestionnaire)
    } catch (err) {
      console.log('Failed to get patient data')
      console.log(err)
      console.log('err response: ', err.response)
      // toast.error(err.response.data.err)
    }
  }

  const [cal, setCal] = useState({
    dob: '',
    age: '',
    timestamp: '',
    estrogenDeficiencyTotal: '',
    estrogenExcessTotal: '',
    progesteroneDeficiencyTotal: '',
    testosteroneDeficiencyTotal: '',
    head: '',
    intestine: '',
    eyes: '',
    urinaryTract: '',
    ears: '',
    skin: '',
    nose: '',
    weight: '',
    allergy: '',
    energy: '',
    mouthThroat: '',
    hormone: '',
    sleep: '',
    mindEmotions: '',
    immune: '',
    dental: '',
    cardiovascular: '',
    liverToxicity: '',
    lung: '',
    other: '',
  })
  const afterGettingData = () => {
    let todayDate, dob, age, timestamp
    todayDate = DateTime.now()
    dob = DateTime.fromFormat(rd.date_of_birth, 'M/d/yyyy')
    age = Math.round(todayDate.diff(dob, 'years').years)
    dob = dob.toFormat('M/d/yyyy')
    timestamp = DateTime.fromFormat(
      rd.timestamp || '',
      'M/d/yyyy H:m:s'
    ).toFormat('M/d/yyyy')

    let estrogenDeficiencyTotal =
      (rd.hot_flashes || 0) +
      (rd.warm_rushes || 0) +
      (rd.night_sweats || 0) +
      (rd.estrogen_weight_gain || 0) +
      (rd.estrogen_depression || 0) +
      (rd.awaken_in_middle_of_night || 0) +
      (rd.back_joint_pain || 0) +
      (rd.kicking_off_covers_at_night || 0) +
      (rd.vaginal_dryness || 0) +
      (rd.heart_palpitations || 0) +
      (rd.mental_fogginess || 0) +
      (rd.estrogen_chest_pain || 0) +
      (rd.racing_mind_at_night || 0) +
      (rd.headaches_migraines || 0) +
      (rd.intestinal_bloating || 0) +
      (rd.diminished_sexuality_sensuality || 0) +
      (rd.estrogen_hair_loss || 0) +
      (rd.pain_in_intercourse || 0)

    let estrogenExcessTotal =
      (rd.breast_tenderness || 0) +
      (rd.estrogen_water_retention || 0) +
      (rd.nipple_tenderness || 0) +
      (rd.swelling || 0) +
      (rd.breast_fullness || 0) +
      (rd.impatient_snappy_with_clear_mind || 0) +
      (rd.estrogen_nausea || 0) +
      (rd.breast_swelling_or_enlargement || 0) +
      (rd.pelvic_cramps || 0)

    let progesteroneDeficiencyTotal =
      (rd.difficulty_sleeping || 0) +
      (rd.anxiety_and_nervousness || 0) +
      (rd.irregular_period || 0) +
      (rd.spotting_before_period || 0) +
      (rd.water_retention || 0) +
      (rd.progesterone_nausea || 0) +
      (rd.infrequent_period || 0) +
      (rd.no_period || 0) +
      (rd.frequent_and_heavy_period || 0) +
      (rd.painful_breasts || 0) +
      (rd.fibrocystic_breasts || 0) +
      (rd.fibroids || 0) +
      (rd.endometriosis || 0) +
      (rd.progesterone_diminished_sex_drive || 0) +
      (rd.pms || 0)

    let testosteroneDeficiencyTotal =
      (rd.diminished_sex_drive || 0) +
      (rd.diminished_sense_of_security || 0) +
      (rd.indecisiveness || 0) +
      (rd.diminished_aggressiveness || 0) +
      (rd.muscle_weakness || 0) +
      (rd.diminished_energy_and_stamina || 0) +
      (rd.muscle_flabbiness || 0) +
      (rd.difficulty_standing_up_from_a_squat || 0) +
      (rd.urine_loss_on_cough || 0) +
      (rd.diminished_coordination_balance || 0) +
      (rd.testosterone_hair_loss || 0) +
      (rd.diminished_armpit_pubic_body_hair || 0) +
      (rd.diminished_love_of_your_body_image || 0)

    let generalPhysicalChangesTotal =
      (rd.weight_gain_or_loss || 0) +
      (rd.increasing_central_weight_beer_belly || 0) +
      (rd.increase_in_breast_fat || 0) +
      (rd.headaches_or_recent_onset_of_migraine_type_headaches || 0) +
      (rd.leg_cramps_or_swollen_ankles || 0) +
      (rd.sleep_problems_sleep_apnea_night_sweats_or_hot_flashes || 0) +
      (rd.emphysema_or_asthma || 0) +
      (rd.chronic_inflammatory_disease_colitis_rheumatoid_arthritis || 0) +
      (rd.varicose_veins_hemorrhoids_or_varicocele || 0) +
      (rd.arthritis_in_shoulders_hands_hips_knees_or_feet || 0) +
      (rd.loss_of_body_hair_or_decreased_beard_growth_rate || 0)

    let metabolicChangesTotal =
      (rd.increased_cholesterol_triglycerides_or_decreased_hdl || 0) +
      (rd.higher_blood_sugar_or_the_onset_of_adult_type_2_diabetes || 0) +
      (rd.mc_high_blood_pressure || 0) +
      (rd.shortness_of_breath_with_exercise_exertion_or_climbing_stairs || 0) +
      (rd.racing_heart_extra_beats_atrial_fibrillation || 0) +
      (rd.development_of_chest_pains_heart_problems_or_blocked_arteries || 0) +
      (rd.past_heart_attack_bypass_surgery_or_stent || 0) +
      (rd.past_stroke_or_tia_mini_stroke || 0) +
      (rd.lightheadedness_dizziness_ringing_in_the_ears || 0) +
      (rd.thyroid_problems || 0) +
      (rd.adrenal_gland_problems || 0)

    let head =
      (rd.headaches || 0) +
      (rd.dizziness || 0) +
      (rd.hair_thinning || 0) +
      (rd.fainting || 0) +
      (rd.convulsions || 0) +
      (rd.hair_loss || 0)

    let eyes =
      (rd.blurred_vision || 0) +
      (rd.eye_pain || 0) +
      (rd.difficulty_in_vision || 0) +
      (rd.double_vision || 0) +
      (rd.itchy_eyes || 0) +
      (rd.diminished_close_up_vision || 0) +
      (rd.spots_in_front_of_eyes || 0) +
      (rd.do_you_wear_glasses || 0)

    let ears =
      (rd.earaches || 0) +
      (rd.ear_infections || 0) +
      (rd.ringing_in_ears || 0) +
      (rd.hearing_loss || 0) +
      (rd.itchy_ears || 0) +
      (rd.change_in_hearing || 0)

    let nose =
      (rd.stuffy_nose || 0) +
      (rd.nasal_discharge || 0) +
      (rd.nosebleeds || 0) +
      (rd.sinus_problems || 0) +
      (rd.sinus_infections || 0) +
      (rd.post_nasal_drip || 0)

    let mouthThroat =
      (rd.canker_sores || 0) +
      (rd.tooth_pain || 0) +
      (rd.sore_gums || 0) +
      (rd.tooth_sensitivity || 0) +
      (rd.bleeding_gums || 0) +
      (rd.coated_tongue || 0) +
      (rd.difficulty_swallowing || 0) +
      (rd.breath_odor || 0) +
      (rd.sore_throat || 0) +
      (rd.do_you_floss || 0)

    let allergy =
      (rd.pollen_allergy || 0) +
      (rd.hay_fever || 0) +
      (rd.dust_allergy || 0) +
      (rd.allergies_asthma || 0) +
      (rd.frequent_sneezing || 0) +
      (rd.seasonal_sneezing || 0) +
      (rd.stuffy_nose_after_eating || 0) +
      (rd.trouble_going_into_shopping_malls || 0) +
      (rd.hypersensitivity_to_medications || 0)

    let sleep =
      (rd.difficulty_in_sleeping || 0) +
      (rd.awaken_in_night || 0) +
      (rd.difficulty_falling_asleep || 0) +
      (rd.difficulty_falling_back_asleep || 0) +
      (rd.sleep_less_than_7_hours || 0) +
      (rd.five_hours_or_less_of_sleep_per_night || 0) +
      (rd.work_night_or_afternoon_shift || 0) +
      (rd.heavy_snoring_or_gasping || 0) +
      (rd.disturbing_dreams || 0) +
      (rd.overall_sleep_quality || 0)

    let immune =
      (rd.cold_sores_in_the_mouth || 0) +
      (rd.colds_or_other_infections || 0) +
      (rd.known_allergies || 0) +
      (rd.swollen_glands || 0) +
      (rd.difficulty_healing || 0)

    let cardiovascular =
      (rd.skipped_heartbeat || 0) +
      (rd.leg_cramping_on_walking || 0) +
      (rd.rapid_or_pounding_heartbeat || 0) +
      (rd.leg_cramps_at_night || 0) +
      (rd.palpitations || 0) +
      (rd.high_blood_pressure || 0) +
      (rd.chest_pain || 0) +
      (rd.pain_in_legs_when_walking || 0) +
      (rd.irregular_heart_beat || 0) +
      (rd.cardiovascular_fluid_retention_swelling || 0) +
      (rd.anemia || 0) +
      (rd.dizzy_upon_standing || 0) +
      (rd.varicose_veins || 0) +
      (rd.bruise_easily || 0)

    let lung =
      (rd.cough || 0) +
      (rd.history_of_smoking || 0) +
      (rd.shortness_of_breath_during_the_day || 0) +
      (rd.asthma || 0) +
      (rd.shortness_of_breath_at_night || 0) +
      (rd.bronchitis || 0) +
      (rd.difficulty_breathing || 0)

    let intestine =
      (rd.nausea || 0) +
      (rd.discomfort_in_abdomen || 0) +
      (rd.vomiting || 0) +
      (rd.foods_you_have_trouble_with || 0) +
      (rd.bloated_feeling || 0) +
      (rd.fatigue_or_anxiety_relieved_by_sweets || 0) +
      (rd.burning_in_stomach || 0) +
      (rd.indigestion_1_2_hours_after_eating || 0) +
      (rd.heartburn || 0) +
      (rd.fullness_long_after_meals || 0) +
      (rd.pain_in_abdomen || 0) +
      (rd.sleeping_after_meals || 0) +
      (rd.diarrhea || 0) +
      (rd.nails_bend_or_break_easily || 0) +
      (rd.constipation || 0) +
      (rd.blood_in_stool || 0) +
      (rd.excessive_belching || 0) +
      (rd.black_stool || 0) +
      (rd.excessing_passing_of_gas || 0) +
      (rd.anal_itch || 0) +
      (rd.indigestion || 0) +
      (rd.pain_on_defecation || 0) +
      (rd.craving_sweets || 0) +
      (rd.hemorrhoids || 0) +
      (rd.hepatitis || 0) +
      (rd.goosebumps_on_back_of_arms || 0) +
      (rd.gallstones || 0) +
      (rd.difficulty_with_oily_foods || 0) +
      (rd.nausea_upon_eating || 0) +
      (rd.difficulty_with_dairy || 0) +
      (rd.change_in_appetite || 0) +
      (rd.difficulty_with_wheat || 0)

    let urinaryTract =
      (rd.burning_or_pain_on_urination || 0) +
      (rd.frequency_of_urination || 0) +
      (rd.bladder_infections || 0) +
      (rd.urgency_of_urination || 0) +
      (rd.kidney_infections || 0) +
      (rd.fluid_retention_swelling || 0) +
      (rd.up_at_night_to_urinate || 0) +
      (rd.kidney_stones || 0) +
      (rd.blood_in_urine || 0)

    let skin =
      (rd.pimples_or_acne || 0) +
      (rd.oily_skin || 0) +
      (rd.dry_skin || 0) +
      (rd.hives || 0) +
      (rd.rashes || 0) +
      (rd.skin_itch || 0) +
      (rd.sweating || 0)

    let weight =
      (rd.compulsive_or_binge_eating || 0) +
      (rd.craving_certain_foods || 0) +
      (rd.weight_sweet_craving || 0) +
      (rd.weight_loss || 0) +
      (rd.excessive_weight || 0) +
      (rd.weight_gain || 0) +
      (rd.underweight || 0) +
      (rd.inability_to_gain_weight || 0)

    let energy =
      (rd.fatigue_in_general || 0) +
      (rd.awaken_energetic_fatigue_easily || 0) +
      (rd.hyperactivity || 0) +
      (rd.awaken_sluggish_improve_with_day || 0)

    let hormone =
      (rd.mid_life_weight_gain || 0) +
      (rd.eyes_sensitive_to_bright_light || 0) +
      (rd.cold_intolerance || 0) +
      (rd.irritable_when_hungry || 0) +
      (rd.swelling_under_eyes || 0) +
      (rd.feel_better_after_exercise || 0) +
      (rd.eye_discomfort_in_bright_light || 0) +
      (rd.feel_worse_after_exercise || 0) +
      (rd.sleep_disturbance || 0) +
      (rd.cold_hands_and_feet || 0) +
      (rd.loss_of_muscle_mass_or_strength || 0) +
      (rd.uncomfortable_in_cold || 0) +
      (rd.sweet_craving || 0) +
      (rd.uncomfortable_in_heat || 0) +
      (rd.fatigue_easy || 0) +
      (rd.dizzy_upon_standing_up_quickly_from_lying_or_sitting || 0) +
      (rd.fatigue_or_irritability_relieved_by_eating_sweets || 0)

    let mindEmotions =
      (rd.poor_memory || 0) +
      (rd.fear || 0) +
      (rd.poor_concentration || 0) +
      (rd.sadness_or_grief || 0) +
      (rd.difficulty_in_making_decisions || 0) +
      (rd.anger || 0) +
      (rd.mood_swings || 0) +
      (rd.shame || 0) +
      (rd.anxiety || 0) +
      (rd.guilt || 0) +
      (rd.nervousness || 0) +
      (rd.self_pity || 0) +
      (rd.depression || 0) +
      (rd.loneliness || 0) +
      (rd.panic_attacks || 0) +
      (rd.meaninglessness || 0) +
      (rd.mid_life_crisis || 0) +
      (rd.hopelessness || 0) +
      (rd.irritability_or_moodiness || 0) +
      (rd.emptiness || 0)

    let dental =
      (rd.tooth_or_gum_pain || 0) +
      (rd.gingivitis || 0) +
      (rd.root_canals || 0) +
      (rd.dental_implants || 0) +
      (rd.mercury_amalgam_fillings || 0)

    let liverToxicity =
      (rd.hypersensitivity_to_odors || 0) +
      (rd.trouble_when_smelling_perfumes || 0) +
      (rd.sleep_disturbance_with_coffee_consumption_after_6_pm || 0) +
      (rd.trouble_with_odors_in_shopping_mall || 0) +
      (rd.known_toxic_exposures || 0)

    let other =
      (rd.present_in_moment || 0) +
      (rd.happiness || 0) +
      (rd.ability_to_accept || 0) +
      (rd.love || 0) +
      (rd.non_attachment || 0) +
      (rd.fun || 0) +
      (rd.laughter || 0)

    setCal({
      ...cal,
      age,
      dob,
      timestamp,
      estrogenDeficiencyTotal,
      estrogenExcessTotal,
      progesteroneDeficiencyTotal,
      testosteroneDeficiencyTotal,
      generalPhysicalChangesTotal,
      metabolicChangesTotal,
      head,
      intestine,
      eyes,
      urinaryTract,
      ears,
      skin,
      nose,
      weight,
      allergy,
      energy,
      mouthThroat,
      hormone,
      sleep,
      mindEmotions,
      immune,
      dental,
      cardiovascular,
      liverToxicity,
      lung,
      other,
    })
  }

  if (!rd) {
    return <></>
  } else {
    return (
      <>
        {/* ============================ */}

        <div id='view'>
          <div
            style={{
              width: '100%',
              paddingRight: 30,
              paddingLeft: 30,
              marginTop: 30,
              marginBottom: 30,
              marginRight: 'auto',
              marginLeft: 'auto',
            }}
          >
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='text-center'
            >
              <tbody>
                <tr className='row_wrap'>
                  <td className='section_title_summary'>
                    Female Health Questionnaire Report
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <tbody>
                <tr>
                  <td width='100%' colSpan={2} className='p-0'>
                    <table
                      cellPadding={0}
                      cellSpacing={0}
                      width='100%'
                      className='subTable'
                    >
                      <tbody>
                        <tr>
                          <td>
                            <b>Name: </b>
                            {rd.name}
                          </td>
                          <td>
                            <b>DOB: </b>
                            {cal.dob}
                          </td>
                          <td>
                            <b>Age: </b>
                            {cal.age}
                          </td>
                          <td>
                            <b>Date of Q: </b>
                            {cal.timestamp}
                          </td>
                          <td>
                            <b>State: </b>
                            {rd.state}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td width='100%' className='p-0'>
                    <table
                      cellPadding={0}
                      cellSpacing={0}
                      width='100%'
                      className='subTable nw-sub-table'
                    >
                      <tbody>
                        <tr>
                          <th>ht tall:</th>
                          {/* <td className='input-field'>{tallestHeightFt}</td> */}
                          {/* <td className='input-field'>{tallestHeightIn}</td> */}
                          <td className='input-field'>
                            {rd.tallest_feet} ft {rd.tallest_inches} in
                          </td>
                          <td className='input-field'>G</td>
                          <td className='input-field'>
                            {rd.total_number_of_times_you_have_been_pregnant}
                          </td>
                          <th>Energy:</th>
                          <td className='input-field'>
                            {rd.how_would_you_rate_your_daily_energy_level}
                          </td>
                          <th>Cognition:</th>
                          <td className='input-field'>
                            {
                              rd.how_sharp_and_clear_do_you_consider_your_memory_and_mental_cognition_at_present
                            }
                          </td>
                          <th>E def:</th>
                          <td className='input-field'>
                            {cal.estrogenDeficiencyTotal}
                          </td>
                          <th>LMP:</th>
                          <td className='input-field'>
                            {
                              rd.your_age_at_the_time_of_your_last_menstrual_period
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>ht now:</th>
                          <td className='input-field'>
                            {rd.current_feet} ft {rd.current_inches} in
                          </td>
                          <td className='input-field'>P</td>
                          <td className='input-field'>
                            {rd.number_of_live_births}
                          </td>
                          <th>Mood:</th>
                          <td className='input-field'>
                            {
                              rd.how_satisfied_are_you_with_your_overall_mood_at_present
                            }
                          </td>
                          <th>Pain:</th>
                          <td className='input-field'>
                            {
                              rd.how_comfortable_pain_free_are_you_feeling_in_your_body
                            }
                          </td>
                          <th>E xs:</th>
                          <td className='input-field'>
                            {cal.estrogenExcessTotal}
                          </td>
                          <th>LRMP:</th>
                          <td className='input-field'>
                            {
                              rd.your_age_at_the_time_your_menstrual_periods_were_last_regular
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>wgt 25:</th>
                          <td className='input-field'>
                            {rd.weight_at_age_25} lb
                          </td>
                          <td className='input-field'>M</td>
                          <td className='input-field'>
                            {rd.number_of_miscarriages}
                          </td>
                          <th>Sleep:</th>
                          <td className='input-field'>
                            {
                              rd.how_satisfied_are_you_with_the_quality_of_your_sleep
                            }
                          </td>
                          <th>BM/wk:</th>
                          <td className='input-field'>
                            {rd.number_of_bowel_movements_per_week}
                          </td>
                          <th>P def:</th>
                          <td className='input-field'>
                            {cal.progesteroneDeficiencyTotal}
                          </td>
                          <th>BT &gt; 35:</th>
                          <td className='input-field'>
                            {rd.after_breast_tenderness}
                          </td>
                        </tr>
                        <tr>
                          <th>wgt now:</th>
                          <td className='input-field'>
                            {rd.current_weight} lb
                          </td>
                          <td className='input-field'>AB</td>
                          <td className='input-field'>
                            {rd.number_of_abortions}
                          </td>
                          <th>Libido:</th>
                          <td className='input-field'>
                            {rd.how_robust_is_your_libido}
                          </td>
                          <th>Constip:</th>
                          <td className='input-field'>{rd.constipated}</td>
                          <th>T def:</th>
                          <td className='input-field'>
                            {cal.testosteroneDeficiencyTotal}
                          </td>
                          <th>PMS &gt; 35:</th>
                          <td className='input-field'>{rd.after_pms}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td width='100%' colSpan={2} className='p-0'>
                    <table
                      cellPadding={0}
                      cellSpacing={0}
                      width='100%'
                      className='subTable lfw-table'
                    >
                      <tbody>
                        <tr>
                          <th>LFW:</th>
                          <td className='input-field'>
                            {rd.when_did_you_last_feel_well}
                          </td>
                        </tr>
                        <tr>
                          <th>CC:</th>
                          <td className='input-field'>
                            {
                              rd.describe_any_symptoms_illnesses_and_or_health_issues_you_are_having_now
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>PMH:</th>
                          <td className='input-field'>
                            {
                              rd.describe_any_major_symptoms_illnesses_and_or_health_issues_you_have_had_in_the_past
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>Wants:</th>
                          <td className='input-field'>
                            {
                              rd.regarding_your_health_what_would_you_most_like_to_accomplish
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>Stress:</th>
                          <td className='input-field'>
                            {
                              rd.what_are_the_main_sources_of_stress_in_your_life
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td width='100%' colSpan={2} className='p-0'>
                    <table
                      cellPadding={0}
                      cellSpacing={0}
                      width='100%'
                      className='subTable lfw-table'
                    >
                      <tbody>
                        <tr>
                          <th>Meds:</th>
                          <td className='input-field'>
                            {
                              rd.list_any_prescription_medications_you_currently_take
                            }
                          </td>
                        </tr>
                        {/* Below fields need to be updated */}
                        <tr>
                          <th>PMH Meds:</th>
                          <td className='input-field'>
                            {
                              rd.list_any_significant_prescription_medications_you_have_taken_in_the_past
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>Med Aller:</th>
                          <td className='input-field'>
                            {
                              rd.list_any_medications_or_drugs_you_are_allergic_to
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td width='100%' colSpan={2} className='p-0'>
                    <table
                      cellPadding={0}
                      cellSpacing={0}
                      width='100%'
                      className='subTable nw-sub-table'
                    >
                      <tbody>
                        <tr>
                          <th>Married:</th>
                          <td className='input-field'> {rd.marital_status}</td>
                          <th>Children:</th>
                          <td className='input-field'>
                            {rd.how_many_children_do_you_have}
                          </td>
                          <th>Committed relationship:</th>
                          <td className='input-field'>
                            {rd.are_you_in_a_committed_relationship}
                          </td>
                          <th>Contraception:</th>
                          <td className='input-field'>
                            {rd.are_you_using_contraception_now}
                          </td>
                          {/* Below fields need to be updated */}
                          <th>Type B.C:</th>
                          <td className='input-field'>
                            {rd.diaphragm ? 'Diaphragm, ' : ''}
                            {rd.condom ? 'Condom, ' : ''}
                            {rd.both ? 'Both, ' : ''}
                            {rd.iud_number_of_years ? 'IUD, ' : ''}
                            {rd.bcm_tubal_ligation ? 'Tubal Ligation, ' : ''}
                            {rd.have_you_ever_taken_a_birth_control_pill
                              ? 'BCP'
                              : ''}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td width='100%' colSpan={2} className='p-0'>
                    <table
                      cellPadding={0}
                      cellSpacing={0}
                      width='100%'
                      className='subTable nw-sub-table'
                    >
                      <tbody>
                        <tr>
                          <th>Energy:</th>
                          <td colSpan={3} className='input-field'>
                            {rd.describe_your_general_energy_level}
                          </td>
                          <th>Energy a.m:</th>
                          <td colSpan={3} className='input-field'>
                            {rd.are_you_energetic_in_the_morning}
                          </td>
                        </tr>
                        <tr>
                          <th>Sleep:</th>
                          <td colSpan={7} className='input-field'>
                            {rd.describe_how_well_you_sleep}
                          </td>
                        </tr>
                        <tr>
                          <th>Sleep Soundly?</th>
                          <td className='input-field'>{rd.sleep_soundly}</td>
                          <th>Trouble falling asleep?:</th>
                          <td className='input-field'>
                            {rd.trouble_falling_asleep}
                          </td>
                          <th>Snore</th>
                          <td className='input-field'>{rd.do_you_snore}</td>
                          <th>Trob stay asleep</th>
                          <td className='input-field'>
                            {rd.trouble_staying_asleep}
                          </td>
                        </tr>
                        <tr>
                          <th>Happy</th>
                          <td className='input-field'>{rd.happy_in_general}</td>
                          <th>Anxious</th>
                          <td className='input-field'>{rd.anxious}</td>
                          <th>Depressed?</th>
                          <td className='input-field'>{rd.depressed}</td>
                          <th>Occ depress</th>
                          <td className='input-field'>
                            {rd.occasionally_depressed}
                          </td>
                        </tr>
                        <tr>
                          <th>% diet organic</th>
                          <td className='input-field'>
                            {rd.what_percentage_of_your_diet_is_organic}{' '}
                          </td>
                          <th></th>
                          <td className='input-field'></td>
                          <th></th>
                          <td className='input-field'></td>
                          <th></th>
                          <td className='input-field'></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} className='p-0'>
                    <table
                      cellPadding={0}
                      cellSpacing={0}
                      width='100%'
                      className='subTable nw-sub-table'
                    >
                      <tbody>
                        <tr>
                          <th className='text-center' colSpan='4'>
                            E Deficiency
                          </th>
                          <th className='text-center' colSpan='2'>
                            E xs
                          </th>
                          <th className='text-center' colSpan='4'>
                            P Deficiency
                          </th>
                          <th className='text-center' colSpan='4'>
                            T Deficiency
                          </th>
                        </tr>
                        <tr>
                          <th>HF</th>
                          <td>{rd.hot_flashes}</td>
                          <th>Depress</th>
                          <td>{rd.estrogen_depression}</td>
                          <th>BT</th>
                          <td>{rd.pregnancy_breast_tenderness}</td>
                          <th>Sleep dist</th>
                          <td>{rd.difficulty_sleeping}</td>
                          <th>Anxiety</th>
                          <td>{rd.anxiety_and_nervousness}</td>
                          <th>libido</th>
                          <td>{rd.diminished_sex_drive}</td>
                          <th>security</th>
                          <td>{rd.diminished_sense_of_security}</td>
                        </tr>
                        <tr>
                          <th>WR</th>
                          <td>{rd.warm_rushes}</td>
                          <th>Wgt</th>
                          <td>{rd.estrogen_weight_gain}</td>
                          <th>NA</th>
                          <td>{rd.estrogen_nausea}</td>
                          <th>irreg period</th>
                          <td>{rd.irregular_period}</td>
                          <th>Spotting</th>
                          <td>{rd.spotting_before_period}</td>
                          <th>Indecisive</th>
                          <td>{rd.indecisiveness}</td>
                          <th>Aggressive</th>
                          <td>{rd.diminished_aggressiveness}</td>
                        </tr>
                        <tr>
                          <th>NS</th>
                          <td>{rd.night_sweats}</td>
                          <th>Awak</th>
                          <td>{rd.awaken_in_middle_of_night}</td>
                          <th>BF</th>
                          <td>{rd.breast_fullness}</td>
                          <th>Edema</th>
                          <td>{rd.water_retention}</td>
                          <th>Nausea</th>
                          <td>{rd.progesterone_nausea}</td>
                          <th>Musc weak</th>
                          <td>{rd.muscle_weakness}</td>
                          <th>Energy</th>
                          <td>{rd.diminished_energy_and_stamina}</td>
                        </tr>
                        <tr>
                          <th>Pain</th>
                          <td>{rd.back_joint_pain}</td>
                          <th>CK</th>
                          <td>{rd.kicking_off_covers_at_night}</td>
                          <th>NT</th>
                          <td>{rd.nipple_tenderness}</td>
                          <th>Infreq period</th>
                          <td>{rd.infrequent_period}</td>
                          <th>Amenorrhea</th>
                          <td>{rd.no_period}</td>
                          <th>Musc flab</th>
                          <td>{rd.muscle_flabbiness}</td>
                          <th>Stand form squat</th>
                          <td>{rd.difficulty_standing_up_from_a_squat}</td>
                        </tr>
                        <tr>
                          <th>VD</th>
                          <td>{rd.vaginal_dryness}</td>
                          <th>Palp</th>
                          <td>{rd.heart_palpitations}</td>
                          <th>Cramp</th>
                          <td>{rd.pelvic_cramps}</td>
                          <th>Freq/heavy</th>
                          <td>{rd.frequent_and_heavy_period}</td>
                          <th>Br pain</th>
                          <td>{rd.painful_breasts}</td>
                          <th>Urinary incontin</th>
                          <td>{rd.urine_loss_on_cough}</td>
                          <th>Balance</th>
                          <td>{rd.diminished_coordination_balance}</td>
                        </tr>
                        <tr>
                          <th>Fog</th>
                          <td>{rd.mental_fogginess}</td>
                          <th>Chest</th>
                          <td>{rd.estrogen_chest_pain}</td>
                          <th>Water &gt;</th>
                          <td>{rd.estrogen_water_retention}</td>
                          <th>Fibrocystic</th>
                          <td>{rd.fibrocystic_breasts}</td>
                          <th>Ut Fibroids</th>
                          <td>{rd.fibroids}</td>
                          <th>Hair loss</th>
                          <td>{rd.testosterone_hair_loss}</td>
                          <th>Body hair</th>
                          <td>{rd.diminished_armpit_pubic_body_hair}</td>
                        </tr>
                        <tr>
                          <th>RM</th>
                          <td>{rd.racing_mind_at_night}</td>
                          <th>HA</th>
                          <td>{rd.headaches_migraines}</td>
                          <th>Swell</th>
                          <td>{rd.swelling}</td>
                          <th>Endometrios</th>
                          <td>{rd.endometriosis}</td>
                          <th>libido</th>
                          <td>{rd.progesterone_diminished_sex_drive}</td>
                          <th>Body image</th>
                          <td>{rd.diminished_love_of_your_body_image}</td>
                          <th></th>
                          <td></td>
                        </tr>
                        <tr>
                          <th>GI</th>
                          <td>{rd.intestinal_bloating}</td>
                          <th>libido</th>
                          <td>{rd.diminished_sexuality_sensuality}</td>
                          <th>impatient</th>
                          <td>{rd.impatient_snappy_with_clear_mind}</td>
                          <th>PMS</th>
                          <td>{rd.pms}</td>
                          <th></th>
                          <td></td>
                          <th></th>
                          <td></td>
                          <th></th>
                          <td></td>
                        </tr>
                        <tr>
                          <th>Hair</th>
                          <td>{rd.estrogen_hair_loss}</td>
                          <th>Dysp</th>
                          <td>{rd.pain_in_intercourse}</td>
                          <th>Breast Swell</th>
                          <td>{rd.breast_swelling_or_enlargement}</td>
                          <th></th>
                          <td></td>
                          <th></th>
                          <td></td>
                          <th></th>
                          <td></td>
                          <th></th>
                          <td></td>
                        </tr>
                        <tr>
                          <th></th>
                          <td></td>
                          <th>Total:</th>
                          <td>{cal.estrogenDeficiencyTotal}</td>
                          <th>Total:</th>
                          <td>{cal.estrogenExcessTotal}</td>
                          <th></th>
                          <td></td>
                          <th>Total:</th>
                          <td>{cal.progesteroneDeficiencyTotal}</td>
                          <th></th>
                          <td></td>
                          <th>Total:</th>
                          <td>{cal.testosteroneDeficiencyTotal}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td className='p-0'>
                    <table
                      cellPadding={0}
                      cellSpacing={0}
                      width='100%'
                      className='subTable nw-sub-table'
                    >
                      <tbody>
                        <tr>
                          <td className='p-0' style={{ border: 'none' }}>
                            <table
                              cellPadding={0}
                              cellSpacing={0}
                              width='100%'
                              className='subTable e-defi-table '
                            >
                              <tbody>
                                <tr>
                                  <th className='text-center' colSpan={4}>
                                    Review of Systems
                                  </th>
                                </tr>
                                <tr>
                                  <th>Head</th>
                                  <td className='input-field'>{cal.head}</td>
                                  <th>Gi</th>
                                  <td className='input-field'>
                                    {cal.intestine}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Eyes</th>
                                  <td className='input-field'>{cal.eyes}</td>
                                  <th>GU</th>
                                  <td className='input-field'>
                                    {cal.urinaryTract}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Ears</th>
                                  <td className='input-field'>{cal.ears}</td>
                                  <th>Skin</th>
                                  <td className='input-field'>{cal.skin}</td>
                                </tr>
                                <tr>
                                  <th>Nose</th>
                                  <td className='input-field'>{cal.nose}</td>
                                  <th>Wgt</th>
                                  <td className='input-field'>{cal.weight}</td>
                                </tr>
                                <tr>
                                  <th>Aller</th>
                                  <td className='input-field'>{cal.allergy}</td>
                                  <th>Energy</th>
                                  <td className='input-field'>{cal.energy}</td>
                                </tr>
                                <tr>
                                  <th>M&amp;T</th>
                                  <td className='input-field'>
                                    {cal.mouthThroat}
                                  </td>
                                  <th>Horm</th>
                                  <td className='input-field'>{cal.hormone}</td>
                                </tr>
                                <tr>
                                  <th>Sleep</th>
                                  <td className='input-field'>{cal.sleep}</td>
                                  <th>M&amp;E</th>
                                  <td className='input-field'>
                                    {cal.mindEmotions}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Immune</th>
                                  <td className='input-field'>{cal.immune}</td>
                                  <th>Dent</th>
                                  <td className='input-field'>{cal.dental}</td>
                                </tr>
                                <tr>
                                  <th>CV</th>
                                  <td className='input-field'>
                                    {cal.cardiovascular}
                                  </td>
                                  <th>Liv/Tox</th>
                                  <td className='input-field'>
                                    {cal.liverToxicity}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Lung</th>
                                  <td className='input-field'>{cal.lung}</td>
                                  <th>Other</th>
                                  <td className='input-field'>{cal.other}</td>
                                </tr>
                                <tr>
                                  <th
                                    colSpan={3}
                                    style={{ textAlign: 'right' }}
                                  >
                                    GRAND TOTAL
                                  </th>
                                  <td className='input-field'>
                                    {cal.head +
                                      cal.intestine +
                                      cal.eyes +
                                      cal.urinaryTract +
                                      cal.ears +
                                      cal.skin +
                                      cal.nose +
                                      cal.weight +
                                      cal.allergy +
                                      cal.energy +
                                      cal.mouthThroat +
                                      cal.hormone +
                                      cal.sleep +
                                      cal.mindEmotions +
                                      cal.immune +
                                      cal.dental +
                                      cal.cardiovascular +
                                      cal.liverToxicity +
                                      cal.lung +
                                      cal.other}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td valign='top' className='p-0'>
                            <table
                              className='subTable nw-sub-table'
                              style={{ width: '100%' }}
                            >
                              <tbody>
                                {/* <tr>
                                  <th className='text-center' colSpan={12}>
                                    Title here ####
                                  </th>
                                </tr> */}
                                <tr>
                                  <th>Pain</th>
                                  <td colSpan='11' className='input-field'>
                                    {
                                      rd.describe_any_chronic_or_occasionally_recurring_pain
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th>Toxic</th>
                                  <td colSpan='11' className='input-field'>
                                    {
                                      rd.describe_any_significant_toxic_exposure_you_have_had_examples_persticides_industrial_other
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th>PMS</th>
                                  <td className='input-field'>{rd.pms}</td>
                                  <th>BT</th>
                                  <td className='input-field'>
                                    {rd.pregnancy_breast_tenderness}
                                  </td>
                                  <th>IUD</th>
                                  <td className='input-field'>
                                    {rd.iud_number_of_years}
                                  </td>
                                  <th>BCP</th>
                                  <td className='input-field'>
                                    {
                                      rd.have_you_ever_taken_a_birth_control_pill
                                    }
                                  </td>
                                  <th>BCP Yrs</th>
                                  <td className='input-field'>
                                    {rd.number_of_years}
                                  </td>
                                  {/* <th>####</th>
                                  <td className='input-field'>####</td> */}
                                </tr>
                                <tr>
                                  <th>Age irreg</th>
                                  <td className='input-field'>
                                    {
                                      rd.your_age_at_the_time_your_menstrual_periods_were_last_regular
                                    }
                                  </td>
                                  <th>Bra</th>
                                  <td className='input-field'>
                                    {
                                      rd.bra_size_when_young_or_prior_to_first_pregnancy
                                    }
                                  </td>
                                  <th>Bra now</th>
                                  <td className='input-field'>
                                    {rd.current_bra_size}
                                  </td>
                                  <th>Br cysts</th>
                                  <td className='input-field'>
                                    {rd.breast_cysts}
                                  </td>
                                  <th>Br Bx</th>
                                  <td className='input-field'>
                                    {rd.breast_biopsy}
                                  </td>
                                  <th>Br Ca</th>
                                  <td className='input-field'>
                                    {rd.breast_cancer}
                                  </td>
                                </tr>
                                <tr>
                                  <th>abn mammo</th>
                                  <td colSpan='5' className='input-field'>
                                    {rd.any_abnormal_mammograms_comment}
                                  </td>
                                  <th>implants</th>
                                  <td className='input-field'>
                                    {rd.do_you_have_breast_implants}
                                  </td>
                                  <th>age imp</th>
                                  <td className='input-field'>
                                    {rd.if_so_at_what_age_implanted}
                                  </td>
                                  {/* <th>####</th>
                                  <td className='input-field'></td> */}
                                </tr>
                                <tr>
                                  <th>hysterex</th>
                                  <td className='input-field'>
                                    {rd.hysterectomy}
                                  </td>
                                  <th>age hyst</th>
                                  <td className='input-field'>
                                    {rd.at_what_age}
                                  </td>
                                  <th>oopherex</th>
                                  <td className='input-field'>
                                    {rd.oopherectomy}
                                  </td>
                                  <th>penia</th>
                                  <td className='input-field'>
                                    {rd.osteopenia}
                                  </td>
                                  <th>porosis</th>
                                  <td className='input-field'>
                                    {rd.osteoperosis}
                                  </td>
                                  <th>nl BMD</th>
                                  <td className='input-field'>
                                    {rd.normal_bone_density}
                                  </td>
                                </tr>
                                <tr>
                                  <th>FH Ca</th>
                                  <td className='input-field'>
                                    {
                                      rd.has_any_member_of_your_family_had_female_cancer
                                    }
                                  </td>
                                  <th>Type Ca FH</th>
                                  <td className='input-field' colSpan='9'>
                                    {rd.who_had_breast_cancer_and_when
                                      ? `${rd.who_had_breast_cancer_and_when}, `
                                      : ''}
                                    {rd.who_had_uterine_cancer_and_when
                                      ? `${rd.who_had_uterine_cancer_and_when}, `
                                      : ''}
                                    {rd.who_had_ovarian_cancer_and_when
                                      ? `${rd.who_had_ovarian_cancer_and_when}`
                                      : ''}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Prem</th>
                                  <td className='input-field'>
                                    {rd.premarin_number_of_years}
                                  </td>
                                  <th>PremPro</th>
                                  <td className='input-field'>
                                    {rd.prempro_number_of_years}
                                  </td>
                                  <th>Patch</th>
                                  <td className='input-field'>
                                    {rd.patch_number_of_years}
                                  </td>
                                  <th>Other Rx</th>
                                  <td colSpan='5' className='input-field'>
                                    {rd.other_hormones_list}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table text-center'
            >
              <tbody>
                <tr className='row_wrap'>
                  <td className='section_title'>Patient Information</td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='sub_table'
            >
              <tbody>
                <tr className='sub_t_tr row_wrap'>
                  <td>
                    <b>Name: </b>
                    {rd.name}
                  </td>
                  <td>
                    <b>Date of Birth: </b>
                    {cal.dob}
                  </td>
                </tr>
                <tr className='sub_t_tr'>
                  <td>
                    <b>Today's Date: </b>
                    {cal.timestamp}
                  </td>
                  <td>
                    <b>Age: </b>
                    {cal.age}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className='s_title'>Home Address:</div>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <tbody>
                <tr className='sub_t_tr'>
                  <td colSpan={3}>
                    <b>Street Address: </b>
                    {rd.street_address}
                  </td>
                </tr>
                <tr className='sub_t_tr'>
                  <td colSpan={3}>
                    <b>Street Address Line 2: </b>
                    {rd.street_address_line_2}
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <b>City: </b>
                    {rd.city}
                  </td>
                  <td>
                    <b>State: </b>
                    {rd.state}
                  </td>
                  <td>
                    <b>Zip: </b>
                    {rd.zip}
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <b>Home Phone: </b>
                  </td>
                  <td>
                    <b>Work Phone: </b>
                    {rd.work_phone}
                  </td>
                  <td>
                    <b>Cell Phone: </b>
                    {rd.cell_phone}
                  </td>
                </tr>
                <tr style={{ width: '100%' }} className='row_wrap'>
                  <td>
                    <b>Email: </b>
                    {rd.email_address}
                  </td>
                  <td>{/* <b>Fax: </b> */}</td>
                  <td className='d_none' />
                </tr>
              </tbody>
            </table>
            {/*  Patient Basic Info */}
            {/* Other Address */}
            <div className='s_title'>Other Address:</div>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <tbody>
                <tr className='row_wrap'>
                  <td colSpan={3}>
                    <b>Street Address: </b>
                    {rd.o_street_address}
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan={3}>
                    <b>Street Address Line 2: </b>
                    {rd.o_street_address_line_2}
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <b>City: </b>
                    {rd.o_city}
                  </td>
                  <td>
                    <b>State: </b>
                    {rd.o_state}
                  </td>
                  <td>
                    <b>Zip: </b>
                    {rd.o_zip}
                  </td>
                </tr>
                {/* <tr className='row_wrap'>
                  <td>
                      <b>Home Phone: </b>
                  </td>
                  <td>
                      <b>Work Phone: </b>
                      {rd.o_work_phone}
                  </td>
                  <td>
                      <b>Cell Phone: </b>
                      {rd.o_cell_phone}
                  </td>
                </tr> */}
                <tr className='row_wrap'>
                  {/* <td>
                      <b>Fax: </b>
                  </td> */}
                  <td>
                    <b>Marital Status: </b>
                    {rd.marital_status}
                  </td>
                  <td />
                </tr>
                <tr className='row_wrap'>
                  <td colSpan={3}>
                    <b>Referred By: </b>
                    {rd.referred_by}
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan={3}>
                    <b>Name of person to contact in case of an emergency: </b>
                    {rd.persons_name}
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan={3}>
                    <b>Emergency contact's relationship to you: </b>
                    {rd.relationship_to_you}
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan={3}>
                    <b>Their phone number: </b>
                    {rd.their_phone_number}
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan={3}>
                    <b>Your Primary Care Physician: </b>
                    {rd.physicians_name}
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan={2} className='w_100 border_bot'>
                    <b>Their fax number: </b>
                    {rd.their_fax_number}
                  </td>
                  <td className='w_100 border_bot'>
                    <b>Their phone number: </b>
                    {rd.physicians_their_phone_number}
                  </td>
                </tr>
              </tbody>
            </table>
            {/* 2nd Stage */}
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='header_table text-center'
            >
              <tbody>
                <tr className='row_wrap'>
                  <td className='section_title'>Medical Overview</td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <tbody>
                <tr>
                  <td colSpan={3}>
                    <table
                      cellPadding={0}
                      cellSpacing={0}
                      align='center'
                      width='100%'
                      className='sub_table'
                    >
                      <tbody>
                        <tr className='sub_t_tr row_wrap'>
                          <td>
                            <b>Name: </b>
                            {rd.name}
                          </td>
                          <td>
                            <b>Date of Birth: </b>
                            {cal.dob}
                          </td>
                        </tr>
                        <tr className='sub_t_tr'>
                          <td>
                            <b>Today's Date: </b>
                            {cal.timestamp}
                          </td>
                          <td>
                            <b>Age: </b>
                            {cal.age}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan={3}>
                    <span>
                      <b>When did you last feel well? </b>
                    </span>
                    <span>{rd.when_did_you_last_feel_well}</span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan={3}>
                    <span>
                      <b>
                        Describe any symptoms, illness and/or health issues you
                        are having now:{' '}
                      </b>
                    </span>
                    <span>
                      {
                        rd.describe_any_symptoms_illnesses_and_or_health_issues_you_are_having_now
                      }
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan={3}>
                    <span>
                      <b>
                        Describe any major symptoms, illness and/or health
                        issues you have had in your past:{' '}
                      </b>
                    </span>
                    <span>
                      {
                        rd.describe_any_major_symptoms_illnesses_and_or_health_issues_you_have_had_in_the_past
                      }
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan={3}>
                    <span>
                      <b>
                        Regarding your health: what would you like most to
                        accomplish?{' '}
                      </b>
                    </span>
                    <span>
                      {
                        rd.regarding_your_health_what_would_you_most_like_to_accomplish
                      }
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan={3}>
                    <span>
                      <b>
                        On a scale of 0-10, with ten being the highest level you
                        experienced, rate your:{' '}
                      </b>
                    </span>
                  </td>
                </tr>
                <tr width='50%' className='row_wrap'>
                  <td className='width:100%'>
                    <b>Energy level: </b>
                    {rd.how_would_you_rate_your_daily_energy_level}
                  </td>
                  <td className='width:100%'>
                    <b>Mood: </b>
                    {rd.how_satisfied_are_you_with_your_overall_mood_at_present}
                  </td>
                  <td className='width:100%'>
                    <b>Sleep: </b>
                    {rd.how_satisfied_are_you_with_the_quality_of_your_sleep}
                  </td>
                </tr>
                <tr width='50%' className='row_wrap'>
                  <td className='width:100%'>
                    <b>Libido: </b>
                    {rd.how_robust_is_your_libido}
                  </td>
                  <td className='width:100%'>
                    <b>Cognitive Function </b>
                    {
                      rd.how_sharp_and_clear_do_you_consider_your_memory_and_mental_cognition_at_present
                    }
                  </td>
                  <td className='width:100%'>
                    <b>Pain: </b>
                    {rd.how_comfortable_pain_free_are_you_feeling_in_your_body}
                  </td>
                </tr>
              </tbody>
            </table>
            {/* Fourth Stage */}
            <div className='s_title'>PAST MEDICAL HISTORY (Please specify)</div>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <tbody>
                <tr>
                  <td></td>
                  <td>
                    <b>CHILDHOOD</b>
                  </td>
                  <td>
                    <b>ADULT</b>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Accident and/or injuries</b>
                  </td>
                  <td>{rd.accident_in_childhood}</td>
                  <td>{rd.accident_as_an_adult}</td>
                </tr>
                <tr>
                  <td>
                    <b>Hospitalizations</b>
                  </td>
                  <td>{rd.hospitalization_in_childhood}</td>
                  <td>{rd.hospitalization_as_an_adult}</td>
                </tr>
                <tr>
                  {/* Below fields need to be updated */}
                  <td>
                    <b>Surgeries</b>
                  </td>
                  <td>{rd.surgeries_in_childhood}</td>
                  <td>{rd.surgeries_as_an_adult}</td>
                </tr>
                <tr>
                  <td>
                    <b>Immunizations</b>
                  </td>
                  <td>{rd.immunizations_in_childhood}</td>
                  <td>{rd.immunizations_as_an_adult}</td>
                </tr>
              </tbody>
            </table>
            <div className='s_title'>SIGNIFICANT ILLNESS IN YOUR FAMILY</div>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <tbody>
                <tr>
                  <td style={{ width: '20%' }}>
                    <span>
                      <b>Mother:</b>
                    </span>
                  </td>
                  <td>
                    <span>{rd.mother}</span>
                  </td>
                </tr>{' '}
                <tr>
                  <td style={{ width: '20%' }}>
                    <span>
                      <b>Maternal Grandmother:</b>
                    </span>
                  </td>
                  <td>
                    <span>{rd.maternal_grandmother}</span>
                  </td>
                </tr>{' '}
                <tr>
                  <td style={{ width: '20%' }}>
                    <span>
                      <b>Maternal Grandfather:</b>
                    </span>
                  </td>
                  <td>
                    <span>{rd.maternal_grandfather}</span>
                  </td>
                </tr>{' '}
                <tr>
                  <td style={{ width: '20%' }}>
                    <span>
                      <b>Father:</b>
                    </span>
                  </td>
                  <td>
                    <span>{rd.father}</span>
                  </td>
                </tr>{' '}
                <tr>
                  <td style={{ width: '20%' }}>
                    <span>
                      <b>Paternal Grandmother:</b>
                    </span>
                  </td>
                  <td>
                    <span>{rd.paternal_grandmother}</span>
                  </td>
                </tr>{' '}
                <tr>
                  <td style={{ width: '20%' }}>
                    <span>
                      <b>Paternal Grandfather:</b>
                    </span>
                  </td>
                  <td>
                    <span>{rd.paternal_grandfather}</span>
                  </td>
                </tr>{' '}
                <tr>
                  <td style={{ width: '20%' }}>
                    <span>
                      <b> Daughter(s) or Son(s):</b>
                    </span>
                  </td>
                  <td>
                    <span>{rd.children}</span>
                  </td>
                </tr>{' '}
                <tr>
                  <td style={{ width: '20%' }}>
                    <span>
                      <b>Other:</b>
                    </span>
                  </td>
                  <td>
                    <span>{rd.other_relatives}</span>
                  </td>
                </tr>{' '}
              </tbody>
            </table>
            <div className='s_title'>History of Medications</div>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <tbody>
                <tr>
                  <td>
                    <span>
                      <b>
                        List any prescription medications you currently take
                        (include strength and time[s] of day):{' '}
                      </b>
                      {rd.list_any_prescription_medications_you_currently_take}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      <b>
                        List any significant prescription medications you have
                        taken in the past:{' '}
                      </b>
                      {
                        rd.list_any_significant_prescription_medications_you_have_taken_in_the_past
                      }
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      <b>List any medications or drugs you are allergic to: </b>
                      {rd.list_any_medications_or_drugs_you_are_allergic_to}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      <b>
                        Estimate the number of days in your lifetime that you
                        have taken antibiotics:{' '}
                      </b>
                      {
                        rd.estimate_the_number_of_days_in_your_lifetime_that_you_have_taken_antibiotics
                      }
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <tbody>
                <tr>
                  <td className='s_title' style={{ width: '50%' }}>
                    <span>Estimate</span>
                  </td>
                  <td className='s_title text-right' style={{ width: '50%' }}>
                    (* = answer optional)
                  </td>
                </tr>
              </tbody>
            </table> */}
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <tbody>
                <tr>
                  <td className='s_title'>
                    <span>Estimate</span>
                  </td>
                  <td className='s_title'>(* = answer optional)</td>
                </tr>

                <tr>
                  <td>
                    <span>
                      <b>Cups of coffee per day: </b>
                      {rd.cups_currently}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>In past: </b>
                      {rd.cups_in_past}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      <b>Number of cigarettes per day*: </b>
                      {rd.cigar_currently}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>In past: </b>
                      {rd.cigar_in_past}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      <b>Daily recreational drug use*: </b>
                      {rd.drug_currently}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>In past: </b>
                      {rd.drug_in_past}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      <b>Daily alcohol intake (what and how much)*: </b>
                      {rd.alcohol_currently}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>In past: </b>
                      {rd.alcohol_in_past}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className='s_title'>
              <span>
                Describe any chronic or occasionally recurring pain:{' '}
                <span style={{ fontWeight: 'normal' }}>
                  {rd.describe_any_chronic_or_occasionally_recurring_pain}
                </span>
              </span>
            </div>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            ></table>
            <div className='s_title'>
              <span>
                Describe any significant toxic exposure you have had (examples:
                pesticide, industrial, other):{' '}
                <span style={{ fontWeight: 'normal' }}>
                  {
                    rd.describe_any_significant_toxic_exposure_you_have_had_examples_persticides_industrial_other
                  }
                </span>
              </span>
            </div>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            ></table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <tbody>
                <tr>
                  <td className='' style={{ width: '70%' }} colSpan={2}>
                    <span>
                      <b>Have you had your mercury amalgams removed? </b>
                      {rd.if_so_have_they_been_removed}
                    </span>
                  </td>
                </tr>{' '}
                <tr>
                  <td style={{ width: '70%' }}>
                    <span>
                      <b>Do you have any root canals? </b>
                      {rd.do_you_have_any_root_canals}
                    </span>
                  </td>
                  <td style={{ width: '70%' }}>
                    <span>
                      <b>How many? </b>
                      {rd.root_canals_yes_how_many}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '70%' }}>
                    <span>
                      <b> Do you have any dental implants? </b>
                      {rd.do_you_have_any_dental_implants}
                    </span>
                  </td>
                  <td style={{ width: '70%' }}>
                    <span>
                      <b>How many? </b>
                      {rd.dental_implants_yes_how_many}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className='' style={{ width: '70%' }} colSpan={2}>
                    <span>
                      <b> Do you eat fish/seafood frequently? </b>
                      {rd.do_you_eat_fish_seafood_frequently}
                    </span>
                  </td>
                </tr>{' '}
                <tr>
                  <td className='' style={{ width: '70%' }} colSpan={2}>
                    <span>
                      <b>Describe current and significant past occupations: </b>
                      {rd.describe_current_and_significant_past_occupations}
                    </span>
                  </td>
                </tr>
              </tbody>
              <tbody />
            </table>
            <div className='s_title'>
              Briefly describe the quality of your personal relationships:{' '}
              <span style={{ fontWeight: 'normal' }}>
                {rd.briefly_describe_the_quality_of_your_personal_relationships}
              </span>
            </div>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Are you married? </b>
                      {rd.marital_status}
                    </span>
                  </td>
                  <td width='50%'>
                    <span>
                      <b>How many children do you have? </b>
                      {rd.how_many_children_do_you_have}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <span>
                      <b>In a committed relationship? </b>
                      {rd.are_you_in_a_committed_relationship}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Using contraception now? </b>
                      {rd.are_you_using_contraception_now}
                    </span>
                  </td>
                  <td width='50%'>
                    <span>
                      <b>What type? </b>
                      {rd.what_type}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <span>
                      <b>
                        Briefly describe the quality of your personal
                        relationships:{' '}
                      </b>
                      {
                        rd.briefly_describe_the_quality_of_your_personal_relationships
                      }
                    </span>
                  </td>
                </tr>
              </tbody>
              <tbody />
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <tbody>
                <tr>
                  <td colSpan={3}>
                    <span>
                      <b>Describe your general energy level: </b>
                      {rd.describe_your_general_energy_level}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <span>
                      <b>Are you energetic in the morning? </b>
                      {rd.are_you_energetic_in_the_morning}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <span>
                      <b>
                        Rate your current energy level with 10 being highest:{' '}
                      </b>
                      {rd.current_energy_level}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <span>
                      <b>
                        Rate the quality of your sleep with 10 being the
                        highest:{' '}
                      </b>
                      {rd.quality_of_sleep}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <span>
                      <b>Describe how well you sleep: </b>
                      {rd.describe_how_well_you_sleep}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <span>
                      <b>Sleep soundly? </b>
                      {rd.sleep_soundly}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <span>
                      <b>Trouble falling asleep? </b>
                      {rd.trouble_falling_asleep}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <span>
                      <b>Do you snore? </b>
                      {rd.do_you_snore}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <span>
                      <b>Trouble staying asleep? </b>
                      {rd.trouble_staying_asleep}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className='s_title'>
              <span>
                Describe your general sense of mood and well-being:{' '}
                <span style={{ fontWeight: 'normal' }}>
                  {rd.describe_your_general_sense_of_mood_and_well_being}
                </span>
              </span>
            </div>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <tbody>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b>Happy in general? </b>
                      {rd.happy_in_general}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Depressed? </b>
                      {rd.depressed}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b>Anxious? </b>
                      {rd.anxious}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Occasionally depressed? </b>
                      {rd.occasionally_depressed}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b>
                        Have you had any stress in your life? Please describe:{' '}
                      </b>
                      {rd.what_are_the_main_sources_of_stress_in_your_life}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b />
                      &nbsp;&nbsp;
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className='s_title'>
              <span> Describe your diet in general terms: </span>
              <span style={{ fontWeight: 'normal' }}>
                {rd.describe_your_diet_in_general_terms}
              </span>
            </div>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <tbody>
                <tr>
                  <td>
                    <span>
                      <b>What percentage of your diet is organic? </b>
                      {rd.what_percentage_of_your_diet_is_organic}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className='s_title'>
              <span>
                Describe your digestion and bowel movements:{' '}
                <span style={{ fontWeight: 'normal' }}>
                  {rd.describe_your_digestion_and_bowel_movements}
                </span>
              </span>
            </div>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <tbody>
                <tr className='row_wrap'>
                  <td width='30%'>
                    <span>
                      <b>Number of times per week? </b>
                      {rd.number_of_bowel_movements_per_week}
                    </span>
                  </td>
                  <td width='30%'>
                    <span>
                      <b>Daily? </b>
                      {rd.daily}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='30%'>
                    <span>
                      <b>Hard? </b>
                      {rd.hard}
                    </span>
                  </td>
                  <td width='30%'>
                    <span>
                      <b>Constipated? </b>
                      {rd.constipated}
                    </span>
                  </td>
                  <td width='30%'>
                    <span>
                      <b>Diarrhea? </b>
                      {rd.mo_diarrhea}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='30%'>
                    <span>
                      <b>Formed? </b>
                      {rd.formed}
                    </span>
                  </td>
                  <td width='30%'>
                    <span>
                      <b>Soft? </b>
                      {rd.soft}
                    </span>
                  </td>
                  <td width='30%'>
                    <span>
                      <b>Voluminous? </b>
                      {rd.voluminous}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan={3}>
                    <span>
                      <b>Have you traveled outside of the United States? </b>
                      {rd.have_you_travelled_outside_of_the_united_states}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan={3}>
                    <span>
                      <b>Where? </b>
                      {rd.where_to}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <span>
                      <b>
                        Did you have a diarrhea illness associated with that
                        travel?{' '}
                      </b>
                      {
                        rd.did_you_have_a_diarrhea_illness_associated_with_that_travel
                      }
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan={3}>
                    <span>
                      <b>Do you exercise regularly? </b>
                      {rd.do_you_exercise_regularly}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan={3}>
                    <span>
                      <b> Please describe: </b>
                      {rd.please_describe}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* Harmone Related */}
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='header_table text-center'
            >
              <tbody>
                <tr className='row_wrap'>
                  <td className='section_title'>
                    Female Hormone Related History
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <tbody>
                <tr>
                  <td colSpan={3}>
                    <table
                      cellPadding={0}
                      cellSpacing={0}
                      align='center'
                      width='100%'
                      className='sub_table'
                    >
                      <tbody>
                        <tr className='sub_t_tr row_wrap'>
                          <td>
                            <b>Name: </b>
                            {rd.name}
                          </td>
                          <td>
                            <b>Date of Birth: </b>
                            {cal.dob}
                          </td>
                        </tr>
                        <tr className='sub_t_tr'>
                          <td>
                            <b>Today's Date: </b>
                            {cal.timestamp}
                          </td>
                          <td>
                            <b>Age: </b>
                            {cal.age}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>
                        Your age at the time of your last menstrual period:{' '}
                      </b>
                      {rd.your_age_at_the_time_of_your_last_menstrual_period}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>
                        Your age at the time your menstrual periods were last
                        regular:
                      </b>{' '}
                      {
                        rd.your_age_at_the_time_your_menstrual_periods_were_last_regular
                      }
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>Age of onset of menstruation (menarche): </b>
                      {rd.age_of_onset_of_menstruation}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>
                        How many years after menarche did your periods become
                        regular?{' '}
                      </b>
                      {
                        rd.how_many_years_after_menarche_did_your_periods_become_regular
                      }
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>
                        How many days did your menstrual flow last at that time?{' '}
                      </b>
                      {
                        rd.how_many_days_did_your_menstrual_flow_last_at_that_time
                      }
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>
                        During your early adulthood, what was the most common
                        length of your cycle? (number of days from first day of
                        menstrual flow of one cycle to the first day of flow of
                        the next)
                      </b>{' '}
                      {
                        rd.during_your_early_adulthood_what_was_the_most_common_length_of_your_complete_cycle
                      }
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>
                        Prior to the age of 18 (or your first pregnancy), did
                        you have:
                      </b>
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b>PMS? </b>
                      {rd.pregnancy_pms}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Bloating? </b>
                      {rd.bloating}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Headaches? </b>
                      {rd.pregnancy_headaches}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b> Irritability? </b>
                      {rd.irritability}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Difficult periods? </b>
                      {rd.difficult_periods}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Breast tenderness? </b>
                      {rd.pregnancy_breast_tenderness}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b>Heavy flow? </b>
                      {rd.heavy_flow}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Uterine cramps? </b>
                      {rd.uterine_cramps}
                    </span>
                  </td>
                  <td> </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>Birth Control Methods: </b>
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b>Diaphragm: </b>
                      {rd.diaphragm}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Condom: </b>
                      {rd.condom}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Both: </b>
                      {rd.both}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b> IUD (number of years): </b>
                      {rd.iud_number_of_years}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Tubal ligation: </b>
                      {rd.bcm_tubal_ligation}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan='2'>
                    <span>
                      <b>Have you ever taken a birth control pill? </b>
                      {rd.have_you_ever_taken_a_birth_control_pill}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Number of years: </b>
                      {rd.number_of_years}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b> If yes, how did you feel on it? </b>
                      {rd.how_did_you_feel_on_it}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>Did you gain weight while on it? </b>
                      {rd.did_you_gain_weight_while_on_it}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b>Number of miscarriages: </b>
                      {rd.number_of_miscarriages}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Number of abortions: </b>
                      {rd.number_of_abortions}
                    </span>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>Pregnancy & Childbirth:</b>
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan='2'>
                    <span>
                      <b>Total number of times you have been pregnant: </b>
                      {rd.total_number_of_times_you_have_been_pregnant}
                    </span>
                  </td>
                  <td colSpan='2'>
                    <span>
                      <b>Number of live births: </b>
                      {rd.number_of_live_births}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>Your age at each pregnancy: </b>
                      {rd.your_age_at_1_st_pregnancy},{' '}
                      {rd.your_age_at_2_nd_pregnancy},{' '}
                      {rd.your_age_at_3_rd_pregnancy},{' '}
                      {rd.your_age_at_4_th_pregnancy},{' '}
                      {rd.your_age_at_5_th_pregnancy},{' '}
                      {rd.your_age_at_6_th_pregnancy},{' '}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>Number of months you breastfed each baby: </b>
                      {rd.number_of_months_you_breastfed_1_st_baby},{' '}
                      {rd.number_of_months_you_breastfed_2_nd_baby},{' '}
                      {rd.number_of_months_you_breastfed_3_rd_baby},{' '}
                      {rd.number_of_months_you_breastfed_4_th_baby},{' '}
                      {rd.number_of_months_you_breastfed_5_th_baby},{' '}
                      {rd.number_of_months_you_breastfed_6_th_baby},{' '}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>Were the last six months of your pregnancy...</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>A very physically pleasant time for you? </b>
                      {rd.a_very_physically_pleasant_time_for_you}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>
                        A time you did not feel as well as when you were not
                        pregnant?
                      </b>{' '}
                      {
                        rd.a_time_you_did_not_feel_as_well_as_when_you_were_not_pregnant
                      }
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>After the first 3 months of pregnancy...</b>
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan='2'>
                    <span>
                      <b>Did you have diabetes? </b>
                      {rd.did_you_develop_diabetes}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan='2'>
                    <span>
                      <b>Did you have nausea? </b>
                      {rd.did_you_have_nausea}
                    </span>
                  </td>
                  <td colSpan='2'>
                    <span>
                      <b>For how long? </b>
                      {rd.for_how_long}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>After the age of 35...</b>
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan='2'>
                    <span>
                      <b>PMS: </b>
                      {rd.after_pms}
                    </span>
                  </td>
                  <td colSpan='2'>
                    <span>
                      <b>Breast tenderness: </b>
                      {rd.after_breast_tenderness}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>At what time in your cycle did you feel best? </b>
                      {rd.at_what_time_in_your_cycle_did_you_feel_best}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>
                        Is this the only time of the month you feel well-being?
                      </b>{' '}
                      {rd.is_this_the_only_time_of_the_month_you_felt_well}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>
                        Is there a time when your periods changed from regular
                        to irregular?{' '}
                      </b>
                      {rd.did_your_period_change}
                    </span>
                    <span>
                      <b> If yes, what age? </b>
                      {rd.if_yes_at_what_age}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>
                        Breast size when young or prior to first pregnancy: Bra
                        Size?
                      </b>{' '}
                      {rd.bra_size_when_young_or_prior_to_first_pregnancy}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>Current Breast Size? Current Bra Size?</b>{' '}
                      {rd.current_bra_size}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>Have you had any of the following? </b>
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b>Breast cysts: </b>
                      {rd.breast_cysts}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Breast biopsy: </b>
                      {rd.breast_biopsy}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Breast cancer: </b>
                      {rd.breast_cancer}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan='2'>
                    <span>
                      <b>Have you had breast mammograms? </b>
                      {rd.have_you_had_breast_mammograms}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>If so, how many? </b>
                      {rd.if_so_how_many}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b>Any abnormal mammograms? (comment) </b>
                      {rd.any_abnormal_mammograms_comment}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan='2'>
                    <span>
                      <b>Have you had breast ultrasounds? </b>
                      {rd.have_you_had_breast_ultrasounds}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>If so, how many? </b>
                      {rd.ultrasounds_if_so_how_many}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b>Any abnormal ultrasounds? (comment) </b>
                      {rd.any_abnormal_ultrasounds_comment}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan='2'>
                    <span>
                      <b>Have you had breast thermograms? </b>
                      {rd.have_you_had_breast_thermograms}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>If so, how many? </b>
                      {rd.thermograms_if_so_how_many}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b>Any abnormal thermograms? (comment) </b>
                      {rd.any_abnormal_thermograms_comment}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td colSpan='2'>
                    <span>
                      <b> Do you have breast implants? </b>
                      {rd.do_you_have_breast_implants}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>If so, at what age implanted? </b>
                      {rd.if_so_at_what_age_implanted}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b>Which type? </b>
                      {rd.yes_which_type}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>
                        What percentage of time in a 24-hour day do you wear a
                        bra?{' '}
                      </b>
                      {rd.what_of_time_in_a_24_hour_day_do_you_wear_a_bra}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>
                      <b>Do you wear underwire bras? </b>
                      {rd.do_you_wear_underwire_bras}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Compression bras? </b>
                      {rd.compression_bras}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='2'>
                    <span>
                      <b>Have you had any of the following?</b>{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b>Uterine fibroids: </b>
                      {rd.uterine_fibroids}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>D and C (number of): </b>
                      {rd.d_and_c}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Ovarian cysts: </b>
                      {rd.ovarian_cysts}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b> Endometriosis: </b>
                      {rd.any_endometriosis}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Laparoscopic surgeries: </b>
                      {rd.laparoscopic_surgeries}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Cesarian sections: </b>
                      {rd.cesarian_sections}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b>Tubal ligation: </b>
                      {rd.tubal_ligation}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Endometrial biopsy: </b>
                      {rd.endometrial_biopsy}
                    </span>
                  </td>
                  <td></td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b>Hysterectomy </b>
                      {rd.hysterectomy}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>at what age? </b>
                      {rd.at_what_age}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b>Oopherectomy (removal of ovary or ovaries): </b>
                      {rd.oopherectomy}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Age of last pap smear </b>
                      {rd.age_of_last_pap_smear}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b>Have you had an abnormal pap smear? </b>
                      {rd.have_you_had_an_abnormal_pap_smear}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>If so, at what age(s)? </b>
                      {rd.if_so_at_what_age}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b>Osteopenia? </b>
                      {rd.osteopenia}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Osteoporosis? </b>
                      {rd.osteoperosis}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Normal Bone density: </b>
                      {rd.normal_bone_density}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>Hormonal Use:</b>{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b>Premarin (number of years): </b>
                      {rd.premarin_number_of_years}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Prempro (number of years): </b>
                      {rd.prempro_number_of_years}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Patch (number of years): </b>
                      {rd.patch_number_of_years}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td>
                    <span>
                      <b>Other hormones (list): </b>
                      {rd.other_hormones_list}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>Has any member of your family had female cancer? </b>
                      {rd.has_any_member_of_your_family_had_female_cancer}
                    </span>
                  </td>
                </tr>
                {/* <tr>
                  <td colSpan='3' left='2'>
                    <span>
                      <b>If yes, what type?</b>
                      {rd.hormone_breast_cancer}, {rd.uterine_cancer},{' '}
                      {rd.ovarian_cancer}
                    </span>
                  </td>
                </tr> */}
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>Breast Cancer: </b>
                      {rd.who_had_breast_cancer_and_when}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>Uterine Cancer: </b>
                      {rd.who_had_uterine_cancer_and_when}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>Ovarian Cancer: </b>
                      {rd.who_had_ovarian_cancer_and_when}
                    </span>
                  </td>
                </tr>
                {/* <tr className='row_wrap'>
                  <td>
                    <span>
                      <b>Uterine cancer: </b>
                      {rd.at_what_age}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Ovarian cancer:</b>
                      {rd.at_what_age}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Who had what? </b>
                      {rd.at_what_age}
                    </span>
                  </td>
                </tr> */}
                <tr>
                  <td colSpan='3'>
                    <span>
                      <b>Current height: </b>
                      {rd.current_feet} ft {rd.current_inches} in
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* Ninth Stage */}
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='header_table text-center'
            >
              <tbody>
                <tr className='row_wrap'>
                  <td className='section_title'>General Health Symptoms</td>
                </tr>
              </tbody>
            </table>
            <div className='s_title'>
              <span>
                Please rate the presence and intensity of the following symptoms
                from 1 to 10:
              </span>
            </div>

            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>0 = Never have symptom</b>
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>10 = Frequently have symptom and effect is severe</b>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <thead>
                <tr>
                  <th
                    style={{ width: '100%', textAlign: 'center' }}
                    colSpan={2}
                  >
                    Head
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Headaches: </b>
                      {rd.headaches}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Dizziness: </b>
                      {rd.dizziness}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Hair thinning: </b>
                      {rd.hair_thinning}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Fainting: </b>
                      {rd.fainting}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Convulsions: </b>
                      {rd.convulsions}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Hair loss: </b>
                      {rd.hair_loss}{' '}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <thead>
                <tr>
                  <th
                    style={{ width: '100%', textAlign: 'center' }}
                    colSpan={2}
                  >
                    Eyes
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Blurred vision: </b>
                      {rd.blurred_vision}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Eye pain: </b>
                      {rd.eye_pain}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Difficulty in vision: </b>
                      {rd.difficulty_in_vision}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Double vision: </b>
                      {rd.double_vision}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Ichy eyes: </b>
                      {rd.itchy_eyes}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Diminished close-up vision: </b>
                      {rd.diminished_close_up_vision}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Spots in front of eyes: </b>
                      {rd.spots_in_front_of_eyes}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Do you wear glasses?: </b>
                      {rd.do_you_wear_glasses}{' '}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <thead>
                <tr>
                  <th
                    style={{ width: '100%', textAlign: 'center' }}
                    colSpan={2}
                  >
                    Ears
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Earaches: </b>
                      {rd.earaches}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Ear infections: </b>
                      {rd.ear_infections}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Ringing in ears: </b>
                      {rd.ringing_in_ears}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Hearing loss: </b>
                      {rd.hearing_loss}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Itchy ears: </b>
                      {rd.itchy_ears}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Change in hearing: </b>
                      {rd.change_in_hearing}{' '}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <thead>
                <tr>
                  <th
                    style={{ width: '100%', textAlign: 'center' }}
                    colSpan={2}
                  >
                    Nose
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Stuffy nose: </b>
                      {rd.stuffy_nose}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Nasal discharge: </b>
                      {rd.nasal_discharge}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Nosebleeds: </b>
                      {rd.nosebleeds}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Sinus problems: </b>
                      {rd.sinus_problems}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Sinus infections: </b>
                      {rd.sinus_infections}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Post nasal drip: </b>
                      {rd.post_nasal_drip}{' '}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <thead>
                <tr>
                  <th
                    style={{ width: '100%', textAlign: 'center' }}
                    colSpan={2}
                  >
                    Allergy
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Pollen allergy: </b>
                      {rd.pollen_allergy}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Hay fever: </b>
                      {rd.hay_fever}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Dust allergy: </b>
                      {rd.dust_allergy}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Asthma: </b>
                      {rd.allergies_asthma}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Frequent sneezing: </b>
                      {rd.frequent_sneezing}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Seasonal sneezing: </b>
                      {rd.seasonal_sneezing}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Stuffy nose after eating: </b>
                      {rd.stuffy_nose_after_eating}{' '}
                    </span>
                  </td>
                  <td>
                    <span>{/* <b></b> */}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <thead>
                <tr>
                  <th
                    style={{ width: '100%', textAlign: 'center' }}
                    colSpan={2}
                  >
                    Mouth and Throat
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Canker sores: </b>
                      {rd.canker_sores}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Tooth pain: </b>
                      {rd.tooth_pain}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Sore Gums: </b>
                      {rd.sore_gums}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Tooth sensitivity: </b>
                      {rd.tooth_sensitivity}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Bleeding gums: </b>
                      {rd.bleeding_gums}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Coated tongue: </b>
                      {rd.coated_tongue}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Difficulty swallowing: </b>
                      {rd.difficulty_swallowing}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Breath odor: </b>
                      {rd.breath_odor}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Sore throat: </b>
                      {rd.sore_throat}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Do you floss: </b>
                      {rd.do_you_floss}{' '}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <thead>
                <tr>
                  <th
                    style={{ width: '100%', textAlign: 'center' }}
                    colSpan={2}
                  >
                    Sleep
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Difficulty in sleeping: </b>
                      {rd.difficulty_in_sleeping}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Awaken in night: </b>
                      {rd.awaken_in_night}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Difficulty in falling asleep: </b>
                      {rd.difficulty_falling_asleep}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Difficulty falling back asleep: </b>
                      {rd.difficulty_falling_back_asleep}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Sleep less than 7 hours: </b>
                      {rd.sleep_less_than_7_hours}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>5 hours or less of sleep per night: </b>
                      {rd.five_hours_or_less_of_sleep_per_night}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Work night or afternoon shift: </b>
                      {rd.work_night_or_afternoon_shift}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Heavy snoring or gasping: </b>
                      {rd.heavy_snoring_or_gasping}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Disturbing dreams: </b>
                      {rd.disturbing_dreams}{' '}
                    </span>
                  </td>
                  <td>
                    <span>{/* <b></b> */}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <thead>
                <tr>
                  <th
                    style={{ width: '100%', textAlign: 'center' }}
                    colSpan={2}
                  >
                    Immune System
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Cold sores in the mouth: </b>
                      {rd.cold_sores_in_the_mouth}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Colds or other infections: </b>
                      {rd.colds_or_other_infections}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Known allergies: </b>
                      {rd.known_allergies}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Swollen glands: </b>
                      {rd.swollen_glands}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Difficulty healing: </b>
                      {rd.difficulty_healing}{' '}
                    </span>
                  </td>
                  <td>
                    <span>{/* <b></b> */}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <thead>
                <tr>
                  <th
                    style={{ width: '100%', textAlign: 'center' }}
                    colSpan={2}
                  >
                    Cardiovascular
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Skipped heartbeat: </b>
                      {rd.skipped_heartbeat}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Leg cramping on walking: </b>
                      {rd.leg_cramping_on_walking}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Rapid OR pounding heartbeat: </b>
                      {rd.rapid_or_pounding_heartbeat}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Leg cramps at night: </b>
                      {rd.leg_cramps_at_night}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Palpitations: </b>
                      {rd.palpitations}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>High blood pressure: </b>
                      {rd.high_blood_pressure}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Chest pain: </b>
                      {rd.chest_pain}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Pain in legs when walking: </b>
                      {rd.pain_in_legs_when_walking}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Irregular heart beat: </b>
                      {rd.irregular_heart_beat}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Fluid retentions (swelling): </b>
                      {rd.cardiovascular_fluid_retention_swelling}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Anemia: </b>
                      {rd.anemia}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Dizzy upon standing: </b>
                      {rd.dizzy_upon_standing}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Varicose veins: </b>
                      {rd.varicose_veins}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Bruise easily: </b>
                      {rd.bruise_easily}{' '}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <thead>
                <tr>
                  <th
                    style={{ width: '100%', textAlign: 'center' }}
                    colSpan={2}
                  >
                    Lungs
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Cough: </b>
                      {rd.cough}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>History of smoking: </b>
                      {rd.history_of_smoking}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Shortness of breath during the day: </b>
                      {rd.shortness_of_breath_during_the_day}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Asthma: </b>
                      {rd.asthma}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Shortness of breath at night: </b>
                      {rd.shortness_of_breath_at_night}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Bronchitis: </b>
                      {rd.bronchitis}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Difficulty in breathing: </b>
                      {rd.difficulty_breathing}{' '}
                    </span>
                  </td>
                  <td>
                    <span>{/* <b /> */}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <thead>
                <tr>
                  <th
                    style={{ width: '100%', textAlign: 'center' }}
                    colSpan={2}
                  >
                    Intestine
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Nausea: </b>
                      {rd.nausea}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Discomfort in abdomen: </b>
                      {rd.discomfort_in_abdomen}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Vomiting: </b>
                      {rd.vomiting}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Foods you have trouble with: </b>
                      {rd.foods_you_have_trouble_with}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Bloated feeling: </b>
                      {rd.bloated_feeling}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Fatigue OR anxiety relieved by sweets: </b>
                      {rd.fatigue_or_anxiety_relieved_by_sweets}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Burning in stomach: </b>
                      {rd.burning_in_stomach}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Indigestion 1-2 hours after eating: </b>
                      {rd.indigestion_1_2_hours_after_eating}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Heartburn: </b>
                      {rd.heartburn}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Fullness long after meal: </b>
                      {rd.fullness_long_after_meals}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Pain in abdomen: </b>
                      {rd.pain_in_abdomen}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Sleepy after meal: </b>
                      {rd.sleeping_after_meals}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Diarrhea: </b>
                      {rd.diarrhea}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Nail bends OR breaks easily: </b>
                      {rd.nails_bend_or_break_easily}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Constipation: </b>
                      {rd.constipation}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Blood in stool: </b>
                      {rd.blood_in_stool}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Excessive Belching: </b>
                      {rd.excessive_belching}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Blood in stool: </b>
                      {rd.blood_in_stool}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Excessive passing of gas: </b>
                      {rd.excessing_passing_of_gas}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Anal itch: </b>
                      {rd.anal_itch}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Indigestion: </b>
                      {rd.indigestion}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Pain on defecation: </b>
                      {rd.pain_on_defecation}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Craving sweets: </b>
                      {rd.craving_sweets}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Hemorrhoids: </b>
                      {rd.hemorrhoids}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Hepatitis: </b>
                      {rd.hepatitis}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Goosebumps on back of arms: </b>
                      {rd.goosebumps_on_back_of_arms}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Gallstones: </b>
                      {rd.gallstones}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Difficulty with oily foods: </b>
                      {rd.difficulty_with_oily_foods}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Nausea upon eating: </b>
                      {rd.nausea_upon_eating}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Difficulty with dairy: </b>
                      {rd.difficulty_with_dairy}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Change in appetite: </b>
                      {rd.change_in_appetite}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Difficulty with wheat: </b>
                      {rd.difficulty_with_wheat}{' '}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <thead>
                <tr>
                  <th
                    style={{ width: '100%', textAlign: 'center' }}
                    colSpan={2}
                  >
                    Urinary Tract
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Burning OR pain on urination: </b>
                      {rd.burning_or_pain_on_urination}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Frequency of urination: </b>
                      {rd.frequency_of_urination}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Bladder infections: </b>
                      {rd.bladder_infections}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Urgency of urination: </b>
                      {rd.urgency_of_urination}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Kidney infections: </b>
                      {rd.kidney_infections}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Fluid retention (swelling): </b>
                      {rd.cardiovascular_fluid_retention_swelling}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Up at night to urinate: </b>
                      {rd.up_at_night_to_urinate}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Kidney stones: </b>
                      {rd.kidney_stones}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Blood in urine: </b>
                      {rd.blood_in_urine}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b />
                      &nbsp;&nbsp;
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <thead>
                <tr>
                  <th
                    style={{ width: '100%', textAlign: 'center' }}
                    colSpan={2}
                  >
                    Skin
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Pimples OR acne: </b>
                      {rd.pimples_or_acne}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Oily skin: </b>
                      {rd.oily_skin}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Dry skin: </b>
                      {rd.dry_skin}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Hives: </b>
                      {rd.hives}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Rashes: </b>
                      {rd.rashes}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Skin itch: </b>
                      {rd.skin_itch}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Sweating: </b>
                      {rd.sweating}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b />
                      &nbsp;&nbsp;
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <thead>
                <tr>
                  <th
                    style={{ width: '100%', textAlign: 'center' }}
                    colSpan={2}
                  >
                    Weight
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Compulsive OR binge eating: </b>
                      {rd.compulsive_or_binge_eating}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Craving certain foods: </b>
                      {rd.craving_certain_foods}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Sweet craving: </b>
                      {rd.weight_sweet_craving}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Weight loss: </b>
                      {rd.weight_loss}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Excessive weight: </b>
                      {rd.excessive_weight}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Weight gain: </b>
                      {rd.weight_gain}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Underweight: </b>
                      {rd.underweight}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Inability to gain weight: </b>
                      {rd.inability_to_gain_weight}{' '}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <thead>
                <tr>
                  <th
                    style={{ width: '100%', textAlign: 'center' }}
                    colSpan={2}
                  >
                    Energy
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Fatigue in general: </b>
                      {rd.fatigue_in_general}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Awaken energetic, fatigue easily: </b>
                      {rd.awaken_energetic_fatigue_easily}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Hyperactivity: </b>
                      {rd.hyperactivity}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Awaken sluggish, improve with day: </b>
                      {rd.awaken_sluggish_improve_with_day}{' '}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <thead>
                <tr>
                  <th
                    style={{ width: '100%', textAlign: 'center' }}
                    colSpan={2}
                  >
                    Hormonal
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Mid-life weight gain : </b>
                      {rd.mid_life_weight_gain}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Eyes sensitive to bright light: </b>
                      {rd.eyes_sensitive_to_bright_light}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Cold intolerance: </b>
                      {rd.cold_intolerance}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Irritable when hungry: </b>
                      {rd.irritable_when_hungry}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Swelling under eyes: </b>
                      {rd.swelling_under_eyes}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Feel better after exercise: </b>
                      {rd.feel_better_after_exercise}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Eye discomfort in bright light: </b>
                      {rd.eye_discomfort_in_bright_light}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Feel worse after exercise: </b>
                      {rd.feel_worse_after_exercise}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Sleep disturbance: </b>
                      {rd.sleep_disturbance}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Cold hands and feet: </b>
                      {rd.cold_hands_and_feet}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Loss of muscle mass OR strength: </b>
                      {rd.loss_of_muscle_mass_or_strength}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Uncomfortable in cold: </b>
                      {rd.uncomfortable_in_cold}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Sweet craving: </b>
                      {rd.sweet_craving}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Uncomfortable in heat: </b>
                      {rd.uncomfortable_in_heat}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Fatigue easily: </b>
                      {rd.fatigue_easy}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>
                        Dissy upon standing up quickly from lying OR sitting:{' '}
                      </b>
                      {rd.dizzy_upon_standing_up_quickly_from_lying_or_sitting}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Fatigue OR irritability relieved by eating sweets: </b>
                      {
                        rd.fatigue_or_irritability_relieved_by_eating_sweets
                      }{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b />
                      &nbsp;&nbsp;
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <thead>
                <tr>
                  <th
                    style={{ width: '100%', textAlign: 'center' }}
                    colSpan={2}
                  >
                    Mind and Emotions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Poor memory: </b>
                      {rd.poor_memory}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Fear: </b>
                      {rd.fear}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Poor concentration: </b>
                      {rd.poor_concentration}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Sadness OR grief: </b>
                      {rd.sadness_or_grief}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Difficulty in making decisions: </b>
                      {rd.difficulty_in_making_decisions}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Anger: </b>
                      {rd.anger}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Mood swings: </b>
                      {rd.mood_swings}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Shame: </b>
                      {rd.shame}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Anxiety: </b>
                      {rd.anxiety}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Guilt: </b>
                      {rd.guilt}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Nervousness: </b>
                      {rd.nervousness}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Self-pity: </b>
                      {rd.self_pity}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Depression: </b>
                      {rd.depression}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Loneliness: </b>
                      {rd.loneliness}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Panic attack: </b>
                      {rd.panic_attacks}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Meaninglessness: </b>
                      {rd.meaninglessness}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Mid-life crisis: </b>
                      {rd.mid_life_crisis}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Hopelessness: </b>
                      {rd.hopelessness}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Irritability OR Moodiness: </b>
                      {rd.irritability_or_moodiness}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Emptiness: </b>
                      {rd.emptiness}{' '}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <thead>
                <tr>
                  <th
                    style={{ width: '100%', textAlign: 'center' }}
                    colSpan={2}
                  >
                    Dental
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Tooth OR gum pain: </b>
                      {rd.tooth_or_gum_pain}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Gingivitis: </b>
                      {rd.gingivitis}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Root canals: </b>
                      {rd.root_canals}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Dental implants: </b>
                      {rd.dental_implants}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Mercury amalgam fillings: </b>
                      {rd.mercury_amalgam_fillings}{' '}
                    </span>
                  </td>
                  <td>
                    <span>{/* <b /> */}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <thead>
                <tr>
                  <th
                    style={{ width: '100%', textAlign: 'center' }}
                    colSpan={2}
                  >
                    Liver and Toxicity
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Hypersensitivity to odors: </b>
                      {rd.hypersensitivity_to_odors}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Trouble when smelling perfumes: </b>
                      {rd.trouble_when_smelling_perfumes}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>
                        Sleep disturbance with coffee consumption after 6pm:{' '}
                      </b>
                      {rd.sleep_disturbance_with_coffee_consumption_after_6_pm}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Trouble with odors in shopping mall: </b>
                      {rd.trouble_with_odors_in_shopping_mall}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Known toxic exposures: </b>
                      {rd.known_toxic_exposures}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Trouble going into shopping malls: </b>
                      {rd.trouble_going_into_shopping_malls}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Hypersensitivity to medications: </b>
                      {rd.hypersensitivity_to_medications}{' '}
                    </span>
                  </td>
                  <td>
                    <span>{/* <b /> */}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding={0}
              cellSpacing={0}
              align='center'
              width='100%'
              className='detail_table'
            >
              <thead>
                <tr>
                  <th
                    style={{ width: '100%', textAlign: 'center' }}
                    colSpan={2}
                  >
                    Other
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Present in moment: </b>
                      {rd.present_in_moment}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Happiness: </b>
                      {rd.happiness}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Ability to accept: </b>
                      {rd.ability_to_accept}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Love: </b>
                      {rd.love}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Non-attachment: </b>
                      {rd.non_attachment}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b>Fun: </b>
                      {rd.fun}{' '}
                    </span>
                  </td>
                </tr>
                <tr className='row_wrap'>
                  <td width='50%'>
                    <span>
                      <b>Laughter: </b>
                      {rd.laughter}{' '}
                    </span>
                  </td>
                  <td>
                    <span>
                      <b />
                      &nbsp;&nbsp;
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* ============================ */}
      </>
    )
  }
}
