import React from 'react'
// import logo from 'images/logo.png'
import classes from './css/LogoHeader.module.css'
import '../questionnaire.css'

function LogoHeader() {
  return (
    <div className={classes.logoBar}>
      <img className={classes.logo} src='images/logo.png' alt='' />
      {/* <img src='images/logo.png' alt='' /> */}
      {/* <h1 className={classes.fontlogo}>Eden</h1> */}
    </div>
  )
}

export default LogoHeader
