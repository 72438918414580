import React from 'react'
import { useLocation } from 'react-router-dom'

import { ConfigProvider } from 'antd'

import LogoHeader from './components/LogoHeader'
import LandingFormPage from './components/female/LandingFormPage'
import FemaleFormProvider from './store/FemaleFormProvider'

function FemaleQuestionnaire() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#47bea7',
          fontFamily: 'Poppins',
          // borderRadius: "",
        },
      }}
    >
      <FemaleFormProvider>
        <div className='questionnaire-background'>
          <div className='questionnaire-container'>
            <LogoHeader />
            <LandingFormPage />
          </div>
        </div>
      </FemaleFormProvider>
    </ConfigProvider>
  )
}

export default FemaleQuestionnaire
