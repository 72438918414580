import React, { useContext, useState, useEffect, Suspense } from 'react'
import { Button, Modal, Progress, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { animateScroll as scroll } from 'react-scroll'
import dayjs from 'dayjs'

import classes from '../css/LandingFormPage.module.css'
import FormContext from '../../store/form-context'
import FormOne from './FormOne'
// import FormTwo from './FormTwo'
// import FormThree from './FormThree'
// import FormFour from './FormFour'
// import FormFive from './FormFive'
// import FormSix from './FormSix'
// import FormSeven from './FormSeven'
// import FormEight from './FormEight'
// import FormNine from './FormNine'
// import FormTen from './FormTen'
// import FormEleven from './FormEleven'
// import FormTwelve from './FormTwelve'

const FormTwo = React.lazy(() => import('./FormTwo'))
const FormThree = React.lazy(() => import('./FormThree'))
const FormFour = React.lazy(() => import('./FormFour'))
const FormFive = React.lazy(() => import('./FormFive'))
const FormSix = React.lazy(() => import('./FormSix'))
const FormSeven = React.lazy(() => import('./FormSeven'))
const FormEight = React.lazy(() => import('./FormEight'))
const FormNine = React.lazy(() => import('./FormNine'))
const FormTen = React.lazy(() => import('./FormTen'))
const FormEleven = React.lazy(() => import('./FormEleven'))
const FormTwelve = React.lazy(() => import('./FormTwelve'))

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

function LandingFormPage() {
  const {
    page,
    setCurrentPage,
    numOfPages,
    formData,
    setFormData,
    setFinalData,
    resetDataState,
  } = useContext(FormContext)

  const pageSet = () => {
    if (page < numOfPages) {
      // jump to next page
      setCurrentPage(page + 1)
      scroll.scrollToTop()
    } else if (page === numOfPages) {
      // if we are already in the last page, set the collected data into a new state and reset the form to default.
      setFinalData((current) => [...current, formData])
      resetDataState()
      setCurrentPage(1)
    }
    // console.log('page:' + page)
    // console.log('totalpages:' + numOfPages)
  }

  const pageSetBack = () => {
    if (page > 1 && page < numOfPages) {
      setCurrentPage(page - 1)
    }
  }

  // Modal for get data from localstorage
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [reRender, setReRender] = useState(0)

  function getFormValues() {
    const storedValues = localStorage.getItem('femaleForm')
    const parsed = JSON.parse(storedValues)
    parsed.date_of_birth = dayjs(parsed.date_of_birth, 'MM/DD/YYYY')
    return parsed
  }

  const showModal = () => {
    setOpen(true)
  }

  const handleOk = () => {
    setConfirmLoading(true)
    setFormData(getFormValues())
    setReRender(reRender + 1)
    setTimeout(() => {
      setOpen(false)
      setConfirmLoading(false)
    }, 2000)
  }
  const handleCancel = () => {
    // console.log('Clicked cancel button')
    localStorage.removeItem('femaleForm')
    setOpen(false)
  }

  useEffect(() => {
    if (localStorage.getItem('femaleForm')) {
      showModal()
    }

    if (
      formData.doctors_office_code === null ||
      formData.doctors_office_code === undefined ||
      formData.doctors_office_code === ''
    ) {
      let doc = new URLSearchParams(window.location.search).get('doc')
      setFormData((prevData) => ({ ...prevData, doctors_office_code: doc }))
      setReRender(reRender + 1)
    }
  }, [])

  return (
    <div className={classes.form}>
      {/* Modal for get data from localstorage */}
      <Modal
        title='Want to resume where you left off last time?'
        open={open}
        // onOk={handleOk}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        centered
        footer={[
          <Button
            key='submit'
            type='primary'
            loading={confirmLoading}
            onClick={handleOk}
            closable={false}
          >
            Yes
          </Button>,
          <Button key='back' onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <p>If so, click "Yes" below</p>
      </Modal>
      {/* Modal for get data from localstorage */}

      <h1 className={classes.headings}>Female Health Questionnaire</h1>
      {/* <PaginateBar /> */}
      {/* <Steps
        current={page - 1}
        className={classes.steps}
        items={[
          { title: 'Step 1' },
          { title: 'Step 2' },
          { title: 'Step 3' },
          { title: 'Step 4' },
          { title: 'Step 5' },
          { title: 'Step 6' },
          { title: 'Step 7' },
          { title: 'Step 8' },
          { title: 'Step 9' },
          { title: 'Step 10' },
          { title: 'Step 11' },
          { title: 'Done!' },
        ]}
      /> */}

      <Progress
        percent={Math.round((page / numOfPages) * 100)}
        status={'active'}
        showInfo={true}
        // steps={12}
        strokeColor={'#47bea7'}
        strokeWidth={15}
      />

      <div className='form-card'>
        {page === 1 && (
          <FormOne key={reRender} backBtn={pageSetBack} nextBtn={pageSet} />
        )}
        <Suspense fallback={<Spin indicator={antIcon} />}>
          {page === 2 && <FormTwo backBtn={pageSetBack} nextBtn={pageSet} />}
          {page === 3 && <FormThree backBtn={pageSetBack} nextBtn={pageSet} />}
          {page === 4 && <FormFour backBtn={pageSetBack} nextBtn={pageSet} />}
          {page === 5 && <FormFive backBtn={pageSetBack} nextBtn={pageSet} />}
          {page === 6 && <FormSix backBtn={pageSetBack} nextBtn={pageSet} />}
          {page === 7 && <FormSeven backBtn={pageSetBack} nextBtn={pageSet} />}
          {page === 8 && <FormEight backBtn={pageSetBack} nextBtn={pageSet} />}
          {page === 9 && <FormNine backBtn={pageSetBack} nextBtn={pageSet} />}
          {page === 10 && <FormTen backBtn={pageSetBack} nextBtn={pageSet} />}
          {page === 11 && (
            <FormEleven backBtn={pageSetBack} nextBtn={pageSet} />
          )}
          {page === 12 && <FormTwelve />}
        </Suspense>
      </div>
    </div>
  )
}

export default LandingFormPage
