import React, { useContext } from 'react'
import { Button, Form, Input, Radio, DatePicker, Col, Row } from 'antd'
import { MaskedInput } from 'antd-mask-input'
import FormContext from '../../store/form-context'
import classes from '../css/Form.module.css'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

function FormOne({ backBtn, nextBtn }) {
  const { formData, setFormData } = useContext(FormContext)
  const [form] = Form.useForm()
  let doc = new URLSearchParams(window.location.search).get('doc')

  const onFinish = (values) => {
    // console.log('Success:', values)
    values.date_of_birth = dayjs(values.date_of_birth).format('MM/DD/YYYY')
    // let updatedValues = values.date_of_birth.format('MM/DD/YYYY')

    setFormData((prevData) => ({
      ...prevData,
      ...values,
    }))
    nextBtn()
  }
  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo)
  }

  return (
    <>
      <Form
        layout='vertical'
        form={form}
        size='large'
        initialValues={{
          ...formData,
          date_of_birth: formData.date_of_birth
            ? dayjs(formData.date_of_birth)
            : '',
        }}
        autoComplete='off'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={(e, all) => {
          // setFormData((prevData) => ({ ...prevData, ...e }))
          all.date_of_birth = dayjs(all.date_of_birth).format('MM/DD/YYYY')
          localStorage.setItem(
            'maleForm',
            JSON.stringify({ ...formData, ...all })
          )
        }}
        // onFieldsChange={(e) => {
        //   setFormData((prevData) => ({ ...prevData, ...e }))
        // }}
        scrollToFirstError={true}
      >
        <div className={classes.divider_title}>Patient Information</div>

        <Row gutter={[24, 24]} style={{ marginBottom: '24px' }}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
            <Form.Item
              label='Name'
              name='name'
              rules={[
                {
                  required: true,
                  message: 'Please input your name!',
                },
              ]}
            >
              <Input placeholder='Your answer' style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
            <Form.Item
              label='Date of Birth'
              name='date_of_birth'
              rules={[
                {
                  required: true,
                  message: 'Please select your date of birth!',
                },
              ]}
            >
              <DatePicker
                format='MM/DD/YYYY'
                placeholder='MM/DD/YYYY'
                onChange={(e) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    date_of_birth: dayjs(e).format('MM/DD/YYYY'),
                  }))
                }}
                disabledTime
                showToday={false}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label='Email address'
          name='email_address'
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input placeholder='name@example.com' />
        </Form.Item>
        {/* Update the field name */}
        <Form.Item
          label="Doctor's office code"
          // name='doctors_email_address'
          name='doctors_office_code'
          rules={[
            {
              required: true,
              message: "Please input your doctor's office code!",
            },
          ]}
        >
          <Input
            placeholder='123456'
            disabled={
              formData.doctors_office_code &&
              formData.doctors_office_code === doc
            }
          />
        </Form.Item>

        <div className={classes.divider_subtitle}>HOME ADDRESS</div>

        <Form.Item label='Street address' name='street_address'>
          <Input placeholder='Your answer' />
        </Form.Item>

        <Form.Item label='Street address line 2' name='street_address_line_2'>
          <Input placeholder='Your answer' />
        </Form.Item>
        <Form.Item label='City' name='city'>
          <Input placeholder='Your answer' />
        </Form.Item>
        <Form.Item label='State' name='state'>
          <Input placeholder='Your answer' />
        </Form.Item>
        <Form.Item label='Zip' name='zip'>
          <Input placeholder='Your answer' />
        </Form.Item>
        <Form.Item label='Work phone' name='work_phone'>
          {/* <Input placeholder='Your answer' /> */}
          <MaskedInput size='large' mask={'000-000-0000'} />
        </Form.Item>
        <Form.Item label='Cell phone' name='cell_phone'>
          <MaskedInput size='large' mask={'000-000-0000'} />
        </Form.Item>
        <div className={classes.divider_subtitle}>ADD OTHER ADDRESS</div>

        <Form.Item label='Street address' name='o_street_address'>
          <Input placeholder='Your answer' />
        </Form.Item>
        <Form.Item label='Street address line 2' name='o_street_address_line_2'>
          <Input placeholder='Your answer' />
        </Form.Item>
        <Form.Item label='City' name='o_city'>
          <Input placeholder='Your answer' />
        </Form.Item>
        <Form.Item label='State' name='o_state'>
          <Input placeholder='Your answer' />
        </Form.Item>
        <Form.Item label='Zip' name='o_zip'>
          <Input placeholder='Your answer' />
        </Form.Item>
        {/* <Form.Item label='Work phone' name='o_work_phone'>
          <MaskedInput size='large' mask={'000-000-0000'} />
        </Form.Item>
        <Form.Item label='Cell phone' name='o_cell_phone'>
          <MaskedInput size='large' mask={'000-000-0000'} />
        </Form.Item> */}
        <Form.Item label='Marital status' name='marital_status'>
          <Radio.Group>
            <Radio value='Single'> Single </Radio>
            <Radio value='Married'> Married </Radio>
            <Radio value='Other'> Other </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label='Referred by' name='referred_by'>
          <Input placeholder='Your answer' />
        </Form.Item>

        <div className={classes.divider_subtitle}>
          NAME OF PERSON TO CONTACT IN CASE OF AN EMERGENCY
        </div>

        <Form.Item label="Person's name" name='persons_name'>
          <Input placeholder='Your answer' />
        </Form.Item>
        <Form.Item label='Relationship to you' name='relationship_to_you'>
          <Input placeholder='Your answer' />
        </Form.Item>
        <Form.Item label='Their phone number' name='their_phone_number'>
          <MaskedInput size='large' mask={'000-000-0000'} />
        </Form.Item>
        <div className={classes.divider_subtitle}>
          YOUR PRIMARY CARE PHYSICIAN
        </div>

        <Form.Item label="Physician's name" name='physicians_name'>
          <Input placeholder='Your answer' />
        </Form.Item>
        <Form.Item
          label='Their phone number'
          name='physicians_their_phone_number'
        >
          <MaskedInput size='large' mask={'000-000-0000'} />
        </Form.Item>
        <Form.Item label='Their fax number' name='their_fax_number'>
          <MaskedInput size='large' mask={'000-000-0000'} />
        </Form.Item>

        <Form.Item>
          <div className={classes.container_row}>
            <Button disabled onClick={backBtn}>
              Back
            </Button>
            <Button type='primary' htmlType='submit'>
              Next
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  )
}

export default FormOne
